import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../assets/styles/pages_css/NewEnterprise.css";
import Navbar from "../../components/Navbar/Navbar";
import clientService from "../../services/Client";
import "../../assets/styles/pages_css/profil.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logobase from "../../assets/sources/images/logobas.png";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Avatar, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import userService from "../../services/Users";
toast.configure();
const Profil_entreprise = () => {
  const useStyles = makeStyles({
    row: {
      backgroundColor: "#16171a",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#131111",
      },
      fontFamily: "Montserrat",
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "black",
      },
    },
  });
  const classes = useStyles();
  let history = useHistory();
  const [site, setSite] = useState("");
  const [profil, setProfil] = useState("");
  // const searchtem = "";
  const [page, setPage] = useState(1);
  const { id_Client } = useParams();
  let numberOfPagesPagination = 1;
  useEffect(() => {
    const detaileEnterprise = async () => {
      const result = await clientService.getById(id_Client);
      setSite(result.data);
    };
    detaileEnterprise();
  }, [id_Client]);
  useEffect(() => {
    const profilEnterprise = async () => {
      const result = await clientService.getById(id_Client);
      setProfil(result.data.users.sort());
    };
    profilEnterprise();
  }, [id_Client]);
  for (let i = 0; i < site.length; i++) {
    if (i <= 10) {
      numberOfPagesPagination = 1;
    } else {
      numberOfPagesPagination = 10;
    }
  }
  async function deleteUsersById(id_User) {
    try {
      let confurmdeleteSite = "vous voulez vraiment effacer cette Enterprise";
      if (window.confirm(confurmdeleteSite)) {
        const { data } = await userService.deleteUserById(id_User);
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
        history.push("/enterprises");
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  return (
    <>
      <Navbar />
      <section className="dt_deux_div">
        <div className="h_detail">
          <h1>
            Dataile et Listes d'utilisateur qu'ils appartiennent à{" "}
            {site.societe}
          </h1>
        </div>
        <div className="profi_ent">
          <section>
            <div className="w_img">
              <img alt="logo_batiment" src={logobase} />
            </div>
          </section>
          <section className="dt_enterprise">
            <ul className="list">
              <li className="list1">Nom De la societe : {site.societe}</li>
              <li className="list1">Secteur d'enterprise : {site.secteur}</li>
              <li className="list1">
                Adresse de enterprise: {site.adresseClient}
              </li>
              <li className="list1"> Pays de l'enterprise : {site.pays}</li>
              <li className="list1">
                Telephonne d'enterprise: {site.telephoneClient}
              </li>
            </ul>
          </section>
        </div>
        <section className="Listes_titre">
          <p>Listes d'Utilisateur qu'ils appartiennent à {site.secteur}</p>
        </section>
        <table className="table">
          <thead>
            <tr>
              <th>User.No</th>
              <th>firstname</th>
              <th>surname</th>
              <th>email</th>
              <th>number</th>
              <th>privileges</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {profil &&
              profil
                .filter((val) => {
                  return val.firstname.toLowerCase();
                  // .includes(searchtem.toLowerCase());
                })
                .slice((page - 1) * 10, (page - 1) * 10 + 10)
                .map((val, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="E.No">
                        <Avatar src="/broken-image.jpg" />
                      </td>
                      <td data-label="societe" className="td_hr">
                        <span to="#">{val.firstname}</span>
                      </td>
                      <td data-label="surname">{val.surname}</td>
                      <td data-label="email">{val.email}</td>
                      <td data-label="email">{val.number}</td>
                      <td data-label="privileges">{val.privileges}</td>
                      <td data-label="Action">
                        <Link className="btn" to={`/EditUser/${val.id_User}`}>
                          <EditIcon />
                        </Link>
                        <button
                          to="#"
                          className="btn"
                          onClick={() => deleteUsersById(val.id_User)}
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <Pagination
          count={numberOfPagesPagination}
          style={{
            padding: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          classes={{ ul: classes.pagination }}
          onChange={(_, value) => {
            setPage(value);
            window.scroll(0, 450);
          }}
        />
      </section>
    </>
  );
};
export default Profil_entreprise;
