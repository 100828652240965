import React, { useEffect, useState } from "react";
import "../../assets/styles/pages_css/enterprises.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import clientService from "../../services/Client";
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
const Enterprises = () => {
  const useStyles = makeStyles({
    row: {
      backgroundColor: "#16171a",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#131111",
      },
      fontFamily: "Montserrat",
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "black",
      },
    },
  });
  const classes = useStyles();
  const [clients, setClient] = useState([]);
  const [searchtem, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  let numberOfPagesPagination = 1;
  useEffect(() => {
    getAllEnterprise();
  }, []);
  const getAllEnterprise = async () => {
    try {
      const result = await clientService.getAll();
      setClient(result.data.clients.sort());
    } catch (error) {}
  };
  for (let i = 0; i < clients.length; i++) {
    if (i <= 10) {
      numberOfPagesPagination = 1;
    } else {
      numberOfPagesPagination = 10;
    }
  }
  const handelSerchTerm = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
  };
  async function deleteEnterprises(id_Client) {
    try {
      let confurmdeleteSite = "vous voulez vraiment effacer cette Enterprise";
      if (window.confirm(confurmdeleteSite)) {
        const { data } = await clientService.deleteEnterpriseById(id_Client);
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
      } else {
        getAllEnterprise();
      }
    } catch (error) {}
  }
  return (
    <>
      <Navbar />
      <div className="search_table">
        <div className="main_entrprises">
          <h1>Toues Les Entreprises Que Vous Gérez</h1>
          <div>
            <div className="searchInputs_table">
              <input
                type="text"
                className="input test_enterprise_search"
                placeholder="Nom de societe"
                onChange={handelSerchTerm}
              />
              <div className="searchIcon_table">
                <SearchIcon />
              </div>
            </div>
          </div>
          <div className="App_enterprise">
            <div className="filterleft_enterprise"></div>
            <table className="table ">
              <thead>
                <tr>
                  <th>E.No</th>
                  <th>Societe/Nom</th>
                  <th>Pays</th>
                  <th>Secteur</th>
                  <th>AdresseClient</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/*  */}
                {clients
                  .filter((val) => {
                    return val.societe
                      .toLowerCase()
                      .includes(searchtem.toLowerCase());
                  })
                  .slice((page - 1) * 10, (page - 1) * 10 + 10)
                  .map((val, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="E.No"> {index + 1}</td>
                        <td data-label="societe" className="td_hr">
                          <Link
                            to={`/enterprises/detaille_entreprise/${val.id_Client}`}
                          >
                            {val.societe}
                          </Link>
                        </td>
                        <td data-label="Pays">{val.pays}</td>
                        <td data-label="Secteur">{val.secteur}</td>
                        <td data-label="AdressClient">{val.adresseClient}</td>
                        <td data-label="Action">
                          <Link
                            className="btn"
                            to={`/enterprises/profil_entreprise/${val.id_Client}`}
                          >
                            <VisibilityIcon />
                          </Link>
                          <Link
                            className="btn "
                            to={`/entreprise/edit/${val.id_Client}`}
                          >
                            <EditIcon />
                          </Link>
                          <Link
                            to="#"
                            className="btn "
                            onClick={() => deleteEnterprises(val.id_Client)}
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Pagination
              count={numberOfPagesPagination}
              style={{
                padding: 10,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              classes={{ ul: classes.pagination }}
              onChange={(_, value) => {
                setPage(value);
                window.scroll(0, 450);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Enterprises;
