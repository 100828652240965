import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "../../assets/styles/pages_css/DetailleEolienne.css";
import "../../assets/styles/pages_css/Detaile_site.css";
import eolienneService from "../../services/Eolienne";
import { Line, defaults } from "react-chartjs-2";
import "../../assets/styles/componentes_css/app_chart.css";
import { CSVLink } from "react-csv";
import dataService from "../../services/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAppOutlined } from "@material-ui/icons";
import Fetching from "../../components/Fetching";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataEole,
  getDataOfToday,
  getDataOfWeek,
  getDataOfYearOrderedByMonth,
  getDataOfYearsOrderedByYear,
} from "../../store/actions/eolienne.action";
toast.configure();
defaults.global.legend.position = "bottom";
const DetailleEolienne = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.eolienn);

  const { site_id, box_id, groupe_id } = useParams();
  const [chartDataDownlod, setchartDataDownlod] = useState("");
  const [mindate, setMindate] = useState("");
  const [maxdate, setMaxdate] = useState("");
  const componentRef = useRef();
  let datas = [];
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const csvLink = useRef();
  const chartRef = useRef(null);
  useEffect(() => {
    const chart = chartRef.current.chartInstance;
    chart.canvas.parentNode.style.width = "90%";
    chart.canvas.parentNode.style.height = "87%";
    chart.options.responsive = true;
    chart.options.maintainAspectRatio = false;
    detaileEolienne();
    dispatch(
      getDataEole({
        id: site_id,
        idOne: groupe_id,
        idtwo: box_id,
      })
    );

  }, [site_id, groupe_id, box_id]);
  const detaileEolienne = async () => {
    try {
      await eolienneService.getEolienneOneById(site_id, groupe_id, box_id);
    } catch (error) {}
  };
  const handleClick = async () => {
    try {
      datas = await dataService.downlod2(
        site_id,
        groupe_id,
        box_id,
        mindate,
        maxdate
      );
      toast.success(
        "vous avez  Selectioné deux data vous pouvez de maitainon télécherge ",
        { position: toast.POSITION.TOP_CENTER }
      );
      setchartDataDownlod(datas.data);
      csvLink.current.link.click();
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const fetchDatasOfHaurGo = (site_id, groupe_id, box_id) => {
    dispatch(
      getDataEole({
        id: site_id,
        idOne: groupe_id,
        idtwo: box_id,
      })
    );
  };

  const fetchDatasOfMonth = (site_id, groupe_id, box_id) => {
    dispatch(
      getDataOfWeek({
        id: site_id,
        idOne: groupe_id,
        idtwo: box_id,
      })
    );
  };
  const fetchDatasOfToday = (site_id, groupe_id, box_id) => {
    dispatch(
      getDataOfToday({
        id: site_id,
        idOne: groupe_id,
        idtwo: box_id,
      })
    );
  };
  const fetchDatasOfYear = (site_id, groupe_id, box_id) => {
    dispatch(
      getDataOfYearOrderedByMonth({
        id: site_id,
        idOne: groupe_id,
        idtwo: box_id,
      })
    );
  };
  const fetchDatasOfYears = (site_id, groupe_id, box_id) => {
    dispatch(
      getDataOfYearsOrderedByYear({
        id: site_id,
        idOne: groupe_id,
        idtwo: box_id,
      })
    );
  };

  return (
    <>
      <Navbar />
      <div
        ref={componentRef}
        className="app_main_detaile_eoliennes  detaile to"
      >
        <div className="telecher_eol to_header">
          <Link to={`/site/${site_id}`} className="previous">
            retour à la page Sites
          </Link>
          <h1 className="header_ditaile_eolienns"> Détail une eolienne </h1>
        </div>
        <div className="e-first_cadre_chatrt to_there">
          <section className="eol_etat_chart_detail">
            <div className="app_chart_Line">
              <Line ref={chartRef}
                data={state.data} 
                width={100}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  position: 'top'
                }
                
              }}/>
            </div>
            <div>
              {state.loading && (
                <span className="loding_p">
                  Loading please wait ...
                  <Fetching />
                </span>
              )}
              <span>
                {state.initcalculations ? (
                  <p className="sum">Production{state.initcalculations} KLW</p>
                ) : (
                  <p className="no_sum">Pas des roduction</p>
                )}
              </span>
              <div className="b_inline">
                {/* <span>Voir la production par</span> */}

                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => fetchDatasOfHaurGo(site_id, groupe_id, box_id)}
                >
                  1/h
                </button>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => fetchDatasOfToday(site_id, groupe_id, box_id)}
                >
                  24/h
                </button>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => fetchDatasOfMonth(site_id, groupe_id, box_id)}
                >
                  1/M
                </button>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => fetchDatasOfYear(site_id, groupe_id, box_id)}
                >
                  1/an
                </button>

                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => fetchDatasOfYears(site_id, groupe_id, box_id)}
                >
                  +/1an
                </button>
              </div>
            </div>
          </section>
          <ul className="list-group-conter_eol ">
            <div className="downlod_div">
              <div>
                <p>Nombre de WindBox total: 1</p>
              </div>
              <div>
                <section className="info_eol">
                  <p value={site_id}>Site id : {site_id} </p>
                  <p value={groupe_id}>Groupe id : {groupe_id} </p>
                  <p value={box_id}>Box id : {box_id} </p>
                </section>
                <div className="deux_label">
                  <label>
                    from :
                    <input
                      name="mindate"
                      type="date"
                      className="input_detai_site_chart_eol"
                      placeholder="mettez le mindate"
                      value={mindate}
                      onChange={(e) => setMindate(e.target.value)}
                      required
                      minLength="4"
                      ref={inputRef1}
                    />
                  </label>
                  <label>
                    To :
                    <input
                      name="maxdate"
                      type="date"
                      className="input_detai_site_chart_eol"
                      placeholder="mettez le maxdate"
                      value={maxdate}
                      onChange={(e) => setMaxdate(e.target.value)}
                      required
                      minLength="4"
                      ref={inputRef2}
                    />
                  </label>
                </div>
              </div>
              <div className="two_button_eol">
                <button onClick={handleClick} className="square subt">
                  Download | <GetAppOutlined />
                </button>
                <CSVLink
                  data={chartDataDownlod}
                  asyncOnClick={true}
                  filename="dataEol.csv"
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                ></CSVLink>
              </div>
            </div>
            <div className="trois_compter_eol">
              <div className="pulse-eol-anim3">
                <span>
                  {(state.initcalculations && state.initcalculations.length
                    ? state.initcalculations[0]
                    : 0
                  ).toFixed(2)}
                </span>
                <p>Kwh</p>
              </div>
            </div>
            <div className="functionmet">
              <p>Etat de functionmet</p>
              <div className="etat_de_funuction">
                <div className="result_function">
                  <div className="cercle_animation"></div>
                  <div className="result_function_pargrgrphe">
                    Etat de functionmet
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
export default DetailleEolienne;
