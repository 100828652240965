import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import windBox from "../../assets/sources/images/WindBox.png";
import Navbar from "../../components/Navbar/Navbar";
import { CSVLink } from "react-csv";
import "../../assets/styles/pages_css/Detaile_site.css";
import dataService from "../../services/Data";
import { Line } from "react-chartjs-2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fetching from "../../components/Fetching";
import { GetAppOutlined } from "@material-ui/icons";
// reducer

import { useDispatch, useSelector } from "react-redux";
import {
  getData,
  getData2,
  getData3,
  getData4,
  getData5,
} from "../../store/actions/site.action";

toast.configure();
const DetailleSite = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.bitcoin);
  const newData = [].concat(...state.site);
  const newDataInitcalculations = [].concat(...state.totalcalculations);
  const newDataEiol = [].concat(...state.listeeolienne);
  // eslint-disable-next-line no-unused-vars
  const [sete, setSite] = useState("");
  const [chartDataDownlod, setchartDataDownlod] = useState("");
  const [mindate, setMindate] = useState("");
  const [maxdate, setMaxdate] = useState("");
  const componentRef = useRef();
  const { id_Site } = useParams();
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const chartRef = useRef(null);
  let datas = [];
  const csvLink = useRef();

  const getData5Memoized = useMemo(() => {
    return (id) => {
      dispatch(getData5({ id }));
    };
  }, [dispatch]);

  useEffect(() => {
    const chart = chartRef.current.chartInstance;
    chart.canvas.parentNode.style.height = "80%";
    chart.canvas.parentNode.style.width = "95%";
    chart.options.responsive = true;
    chart.options.maintainAspectRatio = false;

    const getDetails = async () => {
      try {
        getData5Memoized(id_Site);
      } catch (error) {}
    };
    getDetails();
  }, [id_Site, getData5Memoized]);
  // ici la funuction handleclick fait apple a extrer le donne selo selectioner et telecharge en format csv
  const handleClick = async () => {
    try {
      datas = await dataService.downlod(id_Site, mindate, maxdate);
      setchartDataDownlod(datas.data);
      csvLink.current.link.click();
      toast.success(
        "vous avez  Selectioné deux data vous pouvez de maitainon téléchercge ",
        { position: toast.POSITION.TOP_CENTER }
      );
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const setStes = () => {
    setSite("");
  };
  // reducer
  const fetchDataOfOnehourGO = (id_Site) => {
    dispatch(
      getData5({
        id: id_Site,
      })
    );
  };
  const fetchData = (id_Site) => {
    //Fetch data from redux using time
    dispatch(
      getData({
        id: id_Site,
      })
    );
  };
  const fetchDatas = (id_Site) => {
    dispatch(
      getData2({
        id: id_Site,
      })
    );
  };
  const fetchDatasYear = (id_Site) => {
    dispatch(
      getData3({
        id: id_Site,
      })
    );
  };
  const fetchDatasday = (id_Site) => {
    dispatch(
      getData4({
        id: id_Site,
      })
    );
  };
  
  return (
    <>
      <Navbar />
      <div ref={componentRef} className="app_main_detaile_site detaile to">
        <div className="to_header"></div>
        <div className="header_ditaile_eolienns">
          <h1> Détail de site, et toutes les Éoliennes</h1>
        </div>
        {newData &&
          newData.map((t, n, { length }) => (
            <details key="{n}">
              <summary>Nom de site : {t.nomSite} </summary>
              <p>Adresse : {t.adresseSite}</p>
              <p>longitude : {t.longitudeSite}</p>
              <p>latitude : {t.latitudeSite}</p>
              <p>Sizeh : {t.buildingSizeh}</p>
              <p>Sizev : {t.buildingSizev}</p>
            </details>
          ))}
        <div className="c-first_cadre_chatrt">
          <section className="etat_chart_detail">
            <div className="app_chart_Line">
              <div className="header_in_chart">
                <h4 className="a_titre">Information technique</h4>
              </div>
              {/* <div> */}
              <div>
                {state.loading && (
                  <span>
                    <Fetching />
                  </span>
                )}
                <Line
                  ref={chartRef}
                  data={state.data}
                  width={100}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                      position: "top",
                    },
                  }}
                />
              </div>
              <div>
                <span>
                  {newDataInitcalculations.length ? (
                    <span className="sum">
                      production {newDataInitcalculations} KLW
                    </span>
                  ) : (
                    <p className="no_sum">Pas de production</p>
                  )}
                </span>
                <div className="b_inline">
                  {/* <span>Voir la production par</span> */}
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => fetchDataOfOnehourGO(id_Site)}
                  >
                    1/h
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => fetchDatasday(id_Site)}
                  >
                    24/h
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => fetchData(id_Site)}
                  >
                    1/M
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => fetchDatas(id_Site)}
                  >
                    1/an
                  </button>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => fetchDatasYear(id_Site)}
                  >
                    +/1an
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
          {/* <hr /> */}
          <div className="non">
            <section className="list-group-conter">
              <div className="section_one">
                <span value={id_Site}></span>
                <label>
                  De :
                  <input
                    name="mindate"
                    type="date"
                    className="input_detai-site"
                    data-date-format="DD MMMM YYYY"
                    placeholder="mettez le mindate"
                    value={mindate}
                    onChange={(e) => setMindate(e.target.value)}
                    required
                    minLength="4"
                    ref={inputRef1}
                  />
                </label>
                <label>
                  à :
                  <input
                    name="maxdate"
                    type="date"
                    className="input_detai-site"
                    placeholder="mettez le maxdate"
                    value={maxdate}
                    onChange={(e) => setMaxdate(e.target.value)}
                    required
                    minLength="4"
                    ref={inputRef2}
                  />
                </label>
                <div className="two_button">
                  <button onClick={handleClick} className="square subt">
                    download | <GetAppOutlined />
                  </button>
                  <CSVLink
                    data={chartDataDownlod}
                    asyncOnClick={true}
                    filename="dataSite.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  ></CSVLink>
                </div>
              </div>
              <div className="trois_compter">
                <div className="pulse-anim2">
                  <span>{newDataEiol.length ? newDataEiol.length : 0}</span>
                  <span className="windbox_instales">WindBox instalées </span>
                </div>
                <div className="pulse-anim3">
                  <span>
                    {newDataInitcalculations.length
                      ? newDataInitcalculations
                      : 0}
                  </span>
                  <span className="total_pro">Production en kwh</span>
                </div>
              </div>
            </section>
            {/* ////// */}
            <div className="functionmet_site">
              <span>Etat de fonctionnement</span>
              <div className="etat_de_funuction">
                <div className="result_function">
                  <div className="cercle_animation"></div>
                  <div className="result_function_pargrgrphe">OK</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="container">
          <section className="first_cadre">
            <div>
              <span>
                {" "}
                WindBox instalées {newDataEiol.length ? newDataEiol.length : 0}
              </span>
            </div>
            <ul className="first_cadre2">
              {newDataEiol &&
                newDataEiol.map((t, n) => (
                  <div key={n} className="box-maintence">
                    <Link
                      className="colore_box_eol"
                      onClick={() => setStes(t.box_id)}
                      to={`/eolienne/${t.site_id}/${t.groupe_id}/${t.box_id}`}
                    >
                      <li className="colore_box">
                        {" "}
                        Eolienne:{t.id_Eolienne}
                        <img
                          className="log_un"
                          src={windBox}
                          alt="logowindBox"
                        />
                      </li>
                      <li className="colore_box">site:{t.site_id}</li>
                      <li className="colore_box"> groupe:{t.groupe_id}</li>
                      <li className="colore_box"> box:{t.box_id}</li>
                    </Link>
                  </div>
                ))}
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};
export default DetailleSite;
