import React from "react";
import { Route, Redirect } from "react-router-dom";
const PrivateRoute = (props) => {
  if (localStorage.getItem("token")) {
    return <Route exact path={props.path} component={props.component} />;
  } else {
    return <Route render={() => <Redirect to="/" />} />;
  }
};
export default PrivateRoute;
