import { toast } from "react-toastify";
import siteService from "../../services/Site";
export const getData = (id_Site) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_BITCOIN",
    });
    const response = await siteService.getByIdOfWeek(id_Site);
    const labels = [];
    const data = [];
    const listeeolienne = [];
    const site = [];
    const PanneauxData = [];
    const Panneauxlebels = [];
    const totalcalculations = [];
    const totalPanneauxDatacalculations = [];
    const totalEolienneDatacalculations = [];
    const PanneauxDataTotale = [];
    const PanneauxlebelsTotale = [];

    if (response.data.obj2 && response.data.obj2.length) {
      for (let i = 0; i < response.data.obj2.length; i++) {
        data.push(response.data.obj2[i].value);
        labels.push(response.data.obj2[i].date);
      }
    }
    if (!response.data.obj2.length) {
      toast.warning(
        "Les  éoliennes n'envoient plus de données depuis plus de 1 an.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }
    if (
      response.data.objCalculSitePano &&
      response.data.objCalculSitePano.length
    ) {
      for (let i = 0; i < response.data.objCalculSitePano.length; i++) {
        PanneauxData.push(response.data.objCalculSitePano[i].value);
        Panneauxlebels.push(response.data.objCalculSitePano[i].date);
      }
    }
    if (!response.data.objCalculSitePano.length) {
      toast.warning(
        "Les  éoliennes n'envoient plus de données depuis plus de 1 an.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }

    for (
      let i = 0;
      i < response.data.CalculSitePanneauxetEolienne.length;
      i++
    ) {
      PanneauxDataTotale.push(
        response.data.CalculSitePanneauxetEolienne[i].value
      );
      PanneauxlebelsTotale.push(
        response.data.CalculSitePanneauxetEolienne[i].date
      );
    }

    const concatTheTwoArray = data.concat(PanneauxData);
    let sum = 0;
    concatTheTwoArray.forEach(function (item) {
      return (sum += item);
    });
    const sumOfEolenneData = data.reduce((total, num) => total + num, 0);
    const sumOfPanneauxData = PanneauxData.reduce(
      (total, num) => total + num,
      0
    );
    totalcalculations.push(sum.toFixed());
    totalPanneauxDatacalculations.push(sumOfPanneauxData.toFixed());
    totalEolienneDatacalculations.push(sumOfEolenneData.toFixed());
    listeeolienne.push(response.data.listeeolienne);
    site.push(response.data.site);
    dispatch({
      type: "SUCCESS_BITCOIN",
      payload: {
        data,
        labels,
        PanneauxData,
        Panneauxlebels,
        site,
        listeeolienne,
        totalcalculations,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
        PanneauxDataTotale,
        PanneauxlebelsTotale,
      },
    });
  } catch (error) {
    dispatch({
      type: "REJECTED_BITCOIN",
      payload: {
        error,
      },
    });
  }
};
// ///
export const getData2 = (id_Site) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_BITCOIN",
    });
    const response = await siteService.getByIdOfMonth(id_Site);
    const labels = [];
    const data = [];
    const listeeolienne = [];
    const site = [];
    const PanneauxData = [];
    const Panneauxlebels = [];
    const totalcalculations = [];
    const PanneauxlebelsTotale = [];
    const PanneauxDataTotale = [];
    const totalPanneauxDatacalculations = [];
    const totalEolienneDatacalculations = [];
    if (
      response.data.CalculSiteOfOneYearorderedByMonths &&
      response.data.CalculSiteOfOneYearorderedByMonths.length
    ) {
      for (
        let i = 0;
        i < response.data.CalculSiteOfOneYearorderedByMonths.length;
        i++
      ) {
        data.push(response.data.CalculSiteOfOneYearorderedByMonths[i].value);
        labels.push(response.data.CalculSiteOfOneYearorderedByMonths[i].date);
      }
    }

    if (!response.data.CalculSiteOfOneYearorderedByMonths.length) {
      toast.warning(
        "Les  éoliennes n'envoient plus de données depuis plus de 1 an.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }

    if (
      response.data.CalculSitePanneauxDataOfOneYearorderedByMonths &&
      response.data.CalculSitePanneauxDataOfOneYearorderedByMonths.length
    ) {
      for (
        let i = 0;
        i < response.data.CalculSitePanneauxDataOfOneYearorderedByMonths.length;
        i++
      ) {
        PanneauxData.push(
          response.data.CalculSitePanneauxDataOfOneYearorderedByMonths[i].value
        );
        Panneauxlebels.push(
          response.data.CalculSitePanneauxDataOfOneYearorderedByMonths[i].date
        );
      }
    }
    if (!response.data.CalculSiteOfOneYearorderedByMonths.length) {
      toast.warning(
        "Les Panneaux  pan n'envoient plus de données depuis plus de 1 an.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }
    for (
      let i = 0;
      i < response.data.CalculSitePanneauxetEolienne.length;
      i++
    ) {
      PanneauxDataTotale.push(
        response.data.CalculSitePanneauxetEolienne[i].value
      );
      PanneauxlebelsTotale.push(
        response.data.CalculSitePanneauxetEolienne[i].date
      );
    }

    const concatTheTwoArray = data.concat(PanneauxData);
    let sum = 0;
    concatTheTwoArray.forEach(function (item) {
      return (sum += item);
    });
    const sumOfEolenneData = data.reduce((total, num) => total + num, 0);
    const sumOfPanneauxData = PanneauxData.reduce(
      (total, num) => total + num,
      0
    );
    totalcalculations.push(sum.toFixed());
    totalPanneauxDatacalculations.push(sumOfPanneauxData.toFixed());
    totalEolienneDatacalculations.push(sumOfEolenneData.toFixed());
    listeeolienne.push(response.data.listeeolienne);
    site.push(response.data.site);
    dispatch({
      type: "SUCCESS_BITCOIN",
      payload: {
        data,
        labels,
        listeeolienne,
        PanneauxData,
        Panneauxlebels,
        PanneauxDataTotale,
        PanneauxlebelsTotale,
        site,
        totalcalculations,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
      },
    });
  } catch (error) {
    dispatch({
      type: "REJECTED_BITCOIN",
      payload: {
        error,
      },
    });
  }
};
//// je suis ici
export const getData3 = (id_Site) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_BITCOIN",
    });
    const response = await siteService.getByIdOfYear(id_Site);

    const labels = [];
    const data = [];
    const listeeolienne = [];
    const site = [];
    const PanneauxData = [];
    const Panneauxlebels = [];
    const PanneauxDataTotale = [];
    const PanneauxlebelsTotale = [];
    const totalcalculations = [];
    const totalPanneauxDatacalculations = [];
    const totalEolienneDatacalculations = [];
    if (
      response.data.CalculSiteOfOneYearorderedByYear &&
      response.data.CalculSiteOfOneYearorderedByYear.length
    ) {
      for (
        let i = 0;
        i < response.data.CalculSiteOfOneYearorderedByYear.length;
        i++
      ) {
        data.push(response.data.CalculSiteOfOneYearorderedByYear[i].value);
        labels.push(response.data.CalculSiteOfOneYearorderedByYear[i].date);
      }
    }
    if (!response.data.CalculSiteOfOneYearorderedByYear.length) {
      toast.warning(
        "Les  éoliennes n'envoient plus de données depuis +/1 an.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }
    if (
      response.data.CalculSitePanneauxDataOfOneYearorderedByYear &&
      response.data.CalculSitePanneauxDataOfOneYearorderedByYear.length
    ) {
      for (
        let i = 0;
        i < response.data.CalculSitePanneauxDataOfOneYearorderedByYear.length;
        i++
      ) {
        PanneauxData.push(
          response.data.CalculSitePanneauxDataOfOneYearorderedByYear[i].value
        );
        Panneauxlebels.push(
          response.data.CalculSitePanneauxDataOfOneYearorderedByYear[i].date
        );
      }
    }

    if (!response.data.CalculSitePanneauxDataOfOneYearorderedByYear.length) {
      toast.warning(
        "Les  Panneaux n'envoient plus de données depuis plus +/1 an.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }
    if (response.data.result && response.data.result.length) {
      for (let i = 0; i < response.data.result.length; i++) {
        PanneauxDataTotale.push(response.data.result[i].value);
        PanneauxlebelsTotale.push(response.data.result[i].date);
      }
    }

    const concatTheTwoArray = data.concat(PanneauxData);
    let sum = 0;
    concatTheTwoArray.forEach(function (item) {
      return (sum += item);
    });
    const sumOfEolenneData = data.reduce((total, num) => total + num, 0);
    const sumOfPanneauxData = PanneauxData.reduce(
      (total, num) => total + num,
      0
    );

    // Pass a function to map
    totalcalculations.push(sum.toFixed());
    totalPanneauxDatacalculations.push(sumOfPanneauxData.toFixed());
    totalEolienneDatacalculations.push(sumOfEolenneData.toFixed());
    listeeolienne.push(response.data.listeeolienne);
    site.push(response.data.site);
    dispatch({
      type: "SUCCESS_BITCOIN",
      payload: {
        data,
        labels,
        PanneauxData,
        Panneauxlebels,
        PanneauxDataTotale,
        PanneauxlebelsTotale,
        listeeolienne,
        site,
        totalcalculations,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
      },
    });
  } catch (error) {
    dispatch({
      type: "REJECTED_BITCOIN",
      payload: {
        error,
      },
    });
  }
};
////
export const getData4 = (id_Site) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_BITCOIN",
    });
    const response = await siteService.getByIdOfDay(id_Site);
    const labels = [];
    const data = [];
    const listeeolienne = [];
    const PanneauxData = [];
    const Panneauxlebels = [];
    const totalcalculations = [];
    const totalPanneauxDatacalculations = [];
    const totalEolienneDatacalculations = [];
    const PanneauxDataTotale = [];
    const PanneauxlebelsTotale = [];
    const site = [];
    if (
      response.data.CalculSiteDataOfToday &&
      response.data.CalculSiteDataOfToday.length
    ) {
      for (let i = 0; i < response.data.CalculSiteDataOfToday.length; i++) {
        data.push(response.data.CalculSiteDataOfToday[i].value);
        labels.push(response.data.CalculSiteDataOfToday[i].date);
      }
    }
    if (!response.data.CalculSiteDataOfToday.length) {
      toast.warning(
        "Les  éoliennes n'envoient plus de données depuis plus de 24 heures.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }
    if (
      response.data.CalculSitePanneauxDataOfToday &&
      response.data.CalculSitePanneauxDataOfToday.length
    ) {
      for (
        let l = 0;
        l < response.data.CalculSitePanneauxDataOfToday.length;
        l++
      ) {
        PanneauxData.push(response.data.CalculSitePanneauxDataOfToday[l].value);
        Panneauxlebels.push(
          response.data.CalculSitePanneauxDataOfToday[l].date
        );
      }
    }
    if (!response.data.CalculSitePanneauxDataOfToday.length) {
      toast.warning(
        "Les  Panneaux n'envoient plus de données depuis plus de 24 heures.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }

    if (
      response.data.CalculSitePanneauxetEolienne &&
      response.data.CalculSitePanneauxetEolienne.length
    ) {
      for (
        let i = 0;
        i < response.data.CalculSitePanneauxetEolienne.length;
        i++
      ) {
        PanneauxDataTotale.push(
          response.data.CalculSitePanneauxetEolienne[i].value
        );
        PanneauxlebelsTotale.push(
          response.data.CalculSitePanneauxetEolienne[i].date
        );
      }
    }
    const concatTheTwoArray = data.concat(PanneauxData);
    let sum = 0;
    concatTheTwoArray.forEach(function (item) {
      return (sum += item);
    });
    const sumOfEolenneData = data.reduce((total, num) => total + num, 0);
    const sumOfPanneauxData = PanneauxData.reduce(
      (total, num) => total + num,
      0
    );
    totalcalculations.push(sum.toFixed());
    totalPanneauxDatacalculations.push(sumOfPanneauxData.toFixed());
    totalEolienneDatacalculations.push(sumOfEolenneData.toFixed());
    listeeolienne.push(response.data.listeeolienne);
    site.push(response.data.site);
    dispatch({
      type: "SUCCESS_BITCOIN",
      payload: {
        data,
        labels,
        listeeolienne,
        site,
        PanneauxData,
        Panneauxlebels,
        PanneauxDataTotale,
        PanneauxlebelsTotale,
        totalcalculations,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
      },
    });
  } catch (error) {
    toast.error(error.response.data.description, {
      position: toast.POSITION.TOP_CENTER,
    });

    dispatch({
      type: "REJECTED_BITCOIN",
      payload: {
        error,
      },
    });
  }
};
export const getData5 = (id_Site) => async (dispatch) => {
  const labels = [];
  const data = [];
  const PanneauxData = [];
  const Panneauxlebels = [];
  const listeeolienne = [];
  const site = [];
  const totalcalculations = [];
  const totalPanneauxDatacalculations = [];
  const totalEolienneDatacalculations = [];
  const PanneauxDataTotale = [];
  const PanneauxlebelsTotale = [];
  try {
    dispatch({
      type: "AWAITING_BITCOIN",
    });
    const response = await siteService.getByIdOfOnHourGo(id_Site);
    if (response.data.CalculSiteEolennDataOfToday && response.data.CalculSiteEolennDataOfToday.length) {
      for (let i = 0; i < response.data.CalculSiteEolennDataOfToday.length; i++) {
        data.push(response.data.CalculSiteEolennDataOfToday[i].value);
        labels.push(response.data.CalculSiteEolennDataOfToday[i].date);
      }
    }
    if (!response.data.CalculSiteEolennDataOfToday.length) {
      toast.warning(
        "Les  éoliennes n'envoient plus de données depuis plus de 1 heure.",
        { position: toast.POSITION.TOP_CENTER }
      );
    }

    if (
      response.data.CalculSitePanneauxData &&
      response.data.CalculSitePanneauxData.length
    ) {
      for (let l = 0; l < response.data.CalculSitePanneauxData.length; l++) {
        PanneauxData.push(response.data.CalculSitePanneauxData[l].value);
        Panneauxlebels.push(response.data.CalculSitePanneauxData[l].date);
      }
    }
    if (!response.data.CalculSitePanneauxData.length) {
      toast.warning(
        "Les  Panneaux n'envoient plus de données depuis plus de 1 heure.",
        { position: toast.POSITION.BOTTOM_RIGHT }
      );
    }

    if (
      response.data.CalculSitePanneauxetEolienne &&
      response.data.CalculSitePanneauxetEolienne.length
    ) {
      for (
        let i = 0;
        i < response.data.CalculSitePanneauxetEolienne.length;
        i++
      ) {
        PanneauxDataTotale.push(
          response.data.CalculSitePanneauxetEolienne[i].value
        );
        PanneauxlebelsTotale.push(
          response.data.CalculSitePanneauxetEolienne[i].date
        );
      }
    }
    const concatTheTwoArray = data.concat(PanneauxData);
    let sum = 0;
    concatTheTwoArray.forEach(function (item) {
      return (sum += item);
    });
    const sumOfEolenneData = data.reduce((total, num) => total + num, 0);
    const sumOfPanneauxData = PanneauxData.reduce(
      (total, num) => total + num,
      0
    );
    listeeolienne.push(response.data.listeeolienne);
    site.push(response.data.site);
    totalEolienneDatacalculations.push(sumOfEolenneData.toFixed());
    totalPanneauxDatacalculations.push(sumOfPanneauxData.toFixed());
    totalcalculations.push(sum.toFixed());
    dispatch({
      type: "SUCCESS_BITCOIN",
      payload: {
        data,
        labels,
        listeeolienne,
        site,
        PanneauxData,
        Panneauxlebels,
        PanneauxDataTotale,
        totalcalculations,
        PanneauxlebelsTotale,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
      },
    });
  } catch (error) {
    toast.error(error.response.data.description, {
      position: toast.POSITION.TOP_CENTER,
    });

    data.push([]);
    labels.push([]);
    site.push([]);
    listeeolienne.push([]);
    totalcalculations.push([]);
    totalPanneauxDatacalculations.push([]);
    totalEolienneDatacalculations.push([]);
    PanneauxDataTotale.push([]);
    PanneauxlebelsTotale.push([]);

    dispatch({
      type: "UPDATEING_STATE",
      payload: {
        data,
        labels,
        site,
        listeeolienne,
        totalcalculations,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
        PanneauxDataTotale,
        PanneauxlebelsTotale,
      },
    });
  }
};
export const upDateState = (id_Site) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_BITCOIN",
    });
    const labels = [];
    const data = [];
    const site = [];
    const listeeolienne = [];
    const totalcalculations = [];
    const totalPanneauxDatacalculations = [];
    const totalEolienneDatacalculations = [];
    const PanneauxDataTotale = [];
    const PanneauxlebelsTotale = [];
    data.push([]);
    labels.push([]);
    site.push([]);
    listeeolienne.push([]);
    totalcalculations.push([]);
    totalPanneauxDatacalculations.push([]);
    totalEolienneDatacalculations.push([]);
    PanneauxDataTotale.push([]);
    PanneauxlebelsTotale.push([]);
    dispatch({
      type: "UPDATEING_STATE",
      payload: {
        data,
        labels,
        site,
        listeeolienne,
        totalcalculations,
        totalPanneauxDatacalculations,
        totalEolienneDatacalculations,
        PanneauxDataTotale,
        PanneauxlebelsTotale,
      },
    });
  } catch (error) {
    dispatch({
      type: "REJECTED_BITCOIN",
      payload: {
        error,
      },
    });
  }
};
