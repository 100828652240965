import api from "./api.js";

const siteService = {
  register: async (
    nomSite,
    longitudeSite,
    latitudeSite,
    adresseSite,
    dateInstall,
    buildingSizeh,
    buildingSizev,
    user_id,
    client_id
  ) => {
    const site = {
      nomSite,
      longitudeSite,
      latitudeSite,
      adresseSite,
      dateInstall,
      buildingSizeh,
      buildingSizev,
      user_id,
      client_id,
    };
    return await api.post("sites/add_site", site);
  },
  getAll: async () => {
    return await api.get("/sites");
  },
  getById: async (id_Site) => {
    return await api.get("/datas/data/of_day/site/" + id_Site);
  },
  getByIdOfDay: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_day/site/" + id_Site);
  },
  getByIdOfOnHourGo: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_hour/site/" + id_Site);
  },
  getByIdOfWeek: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_week/site/" + id_Site);
  },
  getByIdOfMonth: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_year/site/" + id_Site);
  },
  getByIdOfYear: async ({ id: id_Site }) => {
    return await api.get("/datas/data/of_years/site/" + id_Site);
  },
  getByIdOne: async (id_Site) => {
    return await api.get("/sites/site/" + id_Site);
  },
  editSiteById: async (id_Site, user) => {
    return await api.put(`/sites/site/edit/${id_Site}`, user);
  },
  deleteSiteById: async (id_Site) => {
    return await api.delete(`/sites/site/delete/${id_Site}`);
  },
};
export default siteService;
