export const initialState = {
  user: [],
  users: [],
  token: null,
  isLogged: false,
  error: null,
  isAuthenticated: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        user: action.payload,
        isLogged: true,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isLogged: false,
      };
    case "GET":
      return {
        ...state,
        users: action.payload,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};
export default reducer;
