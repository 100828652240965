import React from "react";
import "../../assets/styles/partials/HomeNav.css";
import helice from "../../assets/sources/images/helice.png";
export const HomeNav = () => {
  return (
    <div className="first_container_nav">
      <div>
        <div className="lignes">
          <div className="l2"></div>
        </div>
        <a className="" href="https://www.windmyroof.com/">
          <img className="Home_Nav_logo_un" src={helice} alt="logohelice" />
        </a>
      </div>
    </div>
  );
};
