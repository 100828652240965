/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "../src/assets/App.css";
import Home from "./pages/Home/Home";
import Site from "./pages/Site/Site";
import Enterprises from "./pages/Enterprises/Enterprises";
import Newclient from "./pages/Enterprises/NewEnterprise";
import Newsite from "./pages/Site/Newsite";
import Newuser from "./pages/Utilisateur/Newuser";
import EditEnterprise from "./pages/Enterprises/EditEnterprise";
import EditSite from "./pages/Site/EditSite";
import DetailleSite from "./pages/Site/DetailleSite";
import Detaille_entreprise from "./pages/Enterprises/Detaille_entreprise";
import DetailleEolienne from "./pages/Eolienne/Detaile_eolienne";
import NotFound from "./components/Partials/NotFound";
import Accueil from "./pages/Home/Accueil_Admin";
import Profil_entreprise from "./pages/Enterprises/ProfilEntreprise";
import Neweolienne from "./pages/Eolienne/New_eolienne";
import privacypolicy from "./components/CookiePopUp/privacypolicy";
import Profile from "./pages/Utilisateur/Profile";
import EditUser from "./pages/Utilisateur/EditUser";
import Newpaneau from "./pages/Paneaux/Newpaneau";
import Footer from "./components/Navbar/Footer";
// import { Help } from "./components/Help";
import PrivateRoute from "./components/PrivateRoute";

const Routing = (props) => {

  return (
    <>
      {" "}
      <Switch>
        <Route exact path="/" component={Accueil} />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/sites" component={Site} />
        <PrivateRoute exact path="/enterprises" component={Enterprises} />
        <PrivateRoute exact path="/neweolienne" component={Neweolienne} />
        <PrivateRoute
          exact
          path="/eolienne/:site_id/:groupe_id/:box_id"
          component={DetailleEolienne}
        />
        <PrivateRoute exact path="/newsite" component={Newsite} />

        <PrivateRoute exact path="/site/:id_Site" component={DetailleSite} />
        <PrivateRoute exact path="/site/edit/:id_Site" component={EditSite} />
        <PrivateRoute exact path="/newclient" component={Newclient} />
        <PrivateRoute
          exact
          path="/entreprise/edit/:id_Client"
          component={EditEnterprise}
        />
        <PrivateRoute
          exact
          path="/enterprises/detaille_entreprise/:id_Client"
          component={Detaille_entreprise}
        />
        <PrivateRoute
          exact
          path="/enterprises/profil_entreprise/:id_Client"
          component={Profil_entreprise}
        />
        <PrivateRoute exact path="/newuser" component={Newuser} />
        <PrivateRoute exact path="/profie" component={Profile} />
        <PrivateRoute exact path="/privacypolicy" component={privacypolicy} />
        <PrivateRoute exact path="/newpaneau" component={Newpaneau} />
        <PrivateRoute exact path="/EditUser/:id_User" component={EditUser} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
};
function App(props) {
  return (
    <BrowserRouter basename="/bMKbMfy5ZgmNfKcDjXkDk">
      <Routing />
    </BrowserRouter>
  );
}
export default App;
