import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "../../assets/styles/pages_css/EditSite.css";
import userService from "../../services/Users";
import { toast } from "react-toastify";
const EditUser = () => {
  let history = useHistory();
  const { id_User } = useParams();
  const [user, setUser] = useState({
    email: "",
    firstname: "",
    adresseSite: "",
    number: "",
  });
  const { email, firstname, surname, number } = user;
  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    loadSite();
  }, []);
  const onSubmit = async (e) => {
    try {
      let confumMessage =
        "Vous êtes sûr de vouloir Modifier les donnes de ce site.";
      if (window.confirm(confumMessage)) {
        e.preventDefault();
        const { data } = await userService.editUserById(id_User, user);
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
        history.push("/enterprises");
      } else {
        // history.push(`/enterprises/profil_entreprise/${id_User}`);
      }
    } catch (error) {}
  };
  const loadSite = async () => {
    try {
      const result = await userService.getById(id_User);
      setUser(result.data);
    } catch (error) {}
  };
  return (
    <>
      <Navbar />
      <div className="App_new_site">
        <form onSubmit={(e) => onSubmit(e)} className="container buttons ">
          <h2> Edit d'un utilisater</h2>
          <div className="inputs">
            <div className="groups">
              <label htmlFor="nomSite" className="label">
                {" "}
                firstname:{" "}
              </label>
              <input
                name="firstname"
                type="firstname"
                className="input"
                value={firstname}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="firstname"
              />
            </div>
            <div className="groupS">
              <label type="text" className="label">
                surname
              </label>
              <input
                name="surname"
                type="surname"
                className="input"
                value={surname}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="sur name"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs">
            <div className="groups">
              <label htmlFor="adresseSite" className="label">
                number
              </label>
              <input
                name="number"
                type="adresseSite"
                className="input"
                value={number}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="number"
              />
            </div>
            <div className="groupS">
              <label htmlFor="latitudeSite" className="label">
                email
              </label>
              <input
                name="email"
                type="email"
                className="input"
                value={email}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="email"
              />
            </div>
          </div>
          {/*  */}
          <div className="submit">
            <input
              type="submit"
              onSubmit={(e) => onSubmit(e)}
              className="btn btn-primary me-2 subt"
              value="Enregister les modifcation"
            />
          </div>
        </form>
      </div>
    </>
  );
};
export default EditUser;
