const initalState = {
  loading: false,
  error: null,
  site: [],
  listeeolienne: [],
  totalPanneauxDatacalculations: [],
  totalEolienneDatacalculations: [],
  totalcalculations: [],
  data: {
    Panneauxlebels:[],
    labels:[],
    PanneauxlebelsTotale:[],
    datasets: [
      {
        data1: [],
        PanneauxData: [],
        PanneauxDataTotale: []
      },
    ],
  },
};
const siteReducer = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_SITE":
      return {
        site: action.payload,
        listeeolienne: payload.listeeolienne,
        isLogged: true,
      };
    case "AWAITING_BITCOIN":
      return {
        ...state,
        loading: true,
      };
    case "REJECTED_BITCOIN":
      return {
        ...state,
        error: payload.error,
        loading: false,
      };
    case "SUCCESS_BITCOIN":
      return {
        ...state,
        loading: false,
        site: payload.site,
        listeeolienne: payload.listeeolienne,
        totalcalculations: payload.totalcalculations,
        totalPanneauxDatacalculations: payload.totalPanneauxDatacalculations,
        totalEolienneDatacalculations: payload.totalEolienneDatacalculations,
        data: {
          labels: payload.PanneauxlebelsTotale,
          datasets: [
            {
              label: "Eole",
              data: payload.data,
              backgroundColor: "rgba(37, 89, 165, 0.4)",
              borderColor: "rgba(37, 150, 190, 0.5)",
              pointBorderColor: "rgba(37, 150, 190, 0.7)",
               borderWidth: 2,
              pointRadius: 3
              
            },
            {
              label: "Pano",
              data: payload.PanneauxData,
              backgroundColor: "rgba(68,175,0, 0.4)",
              borderColor: "#00A8AC",
              pointBorderColor: "#00A8AC",
            },
            {
              label: "total",
              data: payload.PanneauxDataTotale,
              backgroundColor: "rgba(0, 167, 211, 0.4)",
              borderColor: "#00a7d3",
              pointBorderColor: "#00a7d3",
            },
          ],
        },
        
      };
    case "UPDATEING_STATE":
      return {
        ...state,
        loading: false,
        site: payload.site,
        listeeolienne: payload.listeeolienne,
        totalcalculations: payload.totalcalculations,
        totalPanneauxDatacalculations: payload.totalPanneauxDatacalculations,
        totalEolienneDatacalculations: payload.totalEolienneDatacalculations,
        data: {
          labels: payload.labels,
          Panneauxlebels: payload.Panneauxlebels,
          PanneauxlebelsTotale: payload.PanneauxlebelsTotale,
          datasets: [
            {
              label: "KW",
              data1: payload.data,
              data: payload.PanneauxData,
              PanneauxlebelsTotale: payload.PanneauxlebelsTotale,
              borderColor: "rgba(238,175,0, 0.5)",
              pointBorderColor: "rgba(238,175,0, 0.7)",
            },
          ],
        },
      };
    default:
      return state;
  }
};
export default siteReducer;
