/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import React from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import siteService from "../../services/Site";
import Navbar from "../../components/Navbar/Navbar";
import "../../assets/styles/pages_css/Site.css";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
const Site = () => {
  const useStyles = makeStyles({
    row: {
      backgroundColor: "#16171a",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#131111",
      },
      fontFamily: "Montserrat",
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "black",
      },
    },
  });
  const classes = useStyles();
  const [site, setSite] = useState([]);
  const [searchtem, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ from: "", to: "" });
  const { id_Site } = useParams();
  const [siteId_idSite, steSiteId_idSite] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [siteId, setSiteId] = useState("");
  let n = 1;
  // pour récupérer tout les site sur la page site
  const getAllSites = async () => {
    try {
      const result = await siteService.getAll();
      setSite(result.data.sites.sort());
      steSiteId_idSite(result.data.eolienne);
      result.data.sites.forEach((element) => {
        setSiteId(element.id_Site);
      });
    } catch (error) {}
  };
  for (let i = 0; i < site.length; i++) {
    if (i <= 10) {
      n = 1;
    } else {
      n = 10;
    }
  }
  // eslint-disable-next-line no-unused-vars
  const handelSerchTerm = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
  };
  async function deleteSite(id_Site) {
    try {
      let confurmdeleteSite = "Voulez-vous vraiment effacer ce site.";
      if (window.confirm(confurmdeleteSite)) {
        const { data } = await siteService.deleteSiteById(id_Site);
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
        getAllSites();
      } else {
        getAllSites();
      }
    } catch (error) {}
  }
  // pour filtere tout les site sur la page site avec la var de rescherch
  const handleFilterDate = (date, field) => {
    // eslint-disable-next-line array-callback-return
    const filteredData = site.filter((item) => {
      if (
        field === "from" &&
        dayjs(item.createdAt).isSameOrAfter(dayjs(date))
      ) {
        return item;
      }
    });
    setSite(filteredData);
  };
  const handleInput = (field) => (event) => {
    const { value } = event.target;
    setFilters({
      ...filters,
      [field]: value,
    });
    switch (field) {
      case "from":
        handleFilterDate(value, "from");
        break;
      case "to":
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getAllSites();
    // filtresite();
  }, []);
  return (
    <>
      <Navbar />
      <div className="main_entrprises">
        <div>
          <div className="App_enterprise_site">
            <h1> Tous Les Sites Que Vous Gérez</h1>
            <div>
              <div className="searchInputs_table">
                <input
                  type="text"
                  className="input test_site_search"
                  placeholder="Nom de Site"
                  onChange={handelSerchTerm}
                />
                <div className="searchIcon_table">
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className="filterleft_site">
              <div></div>
            </div>
            {/*  */}
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th>S.No</th>
                  <th scope="col">NomSite</th>
                  <th scope="col">AdresseSite</th>
                  <th scope="col">DateInstall</th>
                  <th scope="col">WindBox</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/*  */}
                {site
                  .filter((val) => {
                    return val.nomSite
                      .toLowerCase()
                      .includes(searchtem.toLowerCase());
                  })
                  .slice((page - 1) * 10, (page - 1) * 10 + 10)
                  .map((val, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="E.No"> {index + 1}</td>
                        <td
                          data-label="Nom Site"
                          className="td_hr_site"
                          to={`/site/${val.id_Site}`}
                        >
                          <Link to={`site/${val.id_Site}`}>
                            {val.nomSite}
                          </Link>
                        </td>
                        <td data-label="Adressede Site">
                          <Link to={`/site/${val.id_Site}`}>
                            {val.adresseSite}
                          </Link>
                        </td>
                        <td data-label="DateInstall">
                          <Link to={`/site/${val.id_Site}`}>
                            {val.dateInstall}
                          </Link>
                        </td>
                        <td data-label="DateInstall">
                          <div>
                            {site.map((element, index) => {
                              if (val.id_Site === element.id_Site) {
                                const fund = siteId_idSite.filter(
                                  (el) => el.site_id === element.id_Site
                                ).length;
                                return (
                                  <Link key={index} to="">
                                    {fund}
                                  </Link>
                                );
                              }
                              //  render nothing
                              return null;
                            })}
                          </div>
                        </td>
                        <td>
                          <Link
                            className="btn"
                            to={`/site/edit/${val.id_Site}`}
                          >
                            <EditIcon />
                          </Link>
                          <Link
                            to="#"
                            className="btn"
                            onClick={() => deleteSite(val.id_Site)}
                          >
                            <DeleteIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Pagination
              count={n}
              style={{
                padding: 10,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              classes={{ ul: classes.pagination }}
              onChange={(_, value) => {
                setPage(value);
                window.scroll(0, 5);
              }}
            />
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};
export default Site;
