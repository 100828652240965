import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../../assets/styles/pages_css/Home.css";
import Navbar from "../../components/Navbar/Navbar";
import siteService from "../../services/Site";
import clientService from "../../services/Client";
import SearchIcon from "@material-ui/icons/Search";
import Typed from "react-typed";
import Fetching from "../../components/Fetching";
import { BsBuilding } from "react-icons/bs";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { upDateState } from "../../store/actions/site.action";

const Home = (props) => {
  const { id_Site } = useParams();
  const [sites, setSites] = useState([]);
  const [client, setClient] = useState([]);
  const [searchtem, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const getAllSites = async () => {
      try {
        const result = await siteService.getAll();
        setSites(result.data.sites.sort());
        result.data.sites.id_Site = nanoid();
        setLoading(false);
      } catch (error) {}
    };
    dispatch(
      upDateState({
        id: id_Site,
      })
    );
    getAllSites();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await clientService.getAll();
        setClient(result.data.clients.sort());
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);
  const handelSerchTermSit = (e) => {
    let value = e.target.value;
    setSearchTerm(value);
  };
  return (
    <>
      <div className="mains">
        <Navbar />
        <div className="App">
          <div className="main">
            <h1 className="searchH">
              {" "}
              Bienvenue Sur La Plateforme D'administration
            </h1>
            <Typed
              className="typed-text"
              strings={[
                "Bienvenue",
                "Sur La Plateforme",
                "D'administration",
                "De Windmyrooof",
              ]}
              typeSpeed={40}
              backSpeed={60}
              loop
            />
            <div className="select_input">
              <input
                className="select"
                type="text"
                name="searchBar"
                id="searchBar"
                placeholder="Recherche"
                onChange={handelSerchTermSit}
              />
              <div className="searchIcon_table_home">
                <SearchIcon />
              </div>
            </div>
            <div className="container top">
              <div className="un_co_r">
                {/* <button onClick={handlePageChange}>click me</button> */}
                <div className="deux_container">
                  <h2 className="titre_un "> Nom des sites</h2>
                  {sites &&
                    sites
                      .filter((val) => {
                        return val.nomSite
                          .toLowerCase()
                          .includes(searchtem.toLowerCase());
                      })
                      .map((val, index) => {
                        return (
                          <ul className="site_ul" key={index}>
                            <Link
                              to={{
                                pathname: `/site/${val.id_Site}`,
                                state: { stateParam: false },
                                location: { key: true },
                              }}
                            >
                              <BsBuilding className="view-icon" />
                              {index + 1}
                              <hr />
                              {loading ? <Fetching /> : val.nomSite}
                            </Link>
                          </ul>
                        );
                      })}
                </div>
              </div>
              {/* */}
              <div className="un_co_l">
                <div className="deux_container">
                  <h2 className="titre_deux">Nom d'entreprises</h2>
                  {client &&
                    client
                      .filter((val) => {
                        return val.societe
                          .toLowerCase()
                          .includes(searchtem.toLowerCase());
                      })
                      .map((val, index) => {
                        return (
                          <ul className="site_ul" key={index}>
                            <Link
                              to={`/enterprises/detaille_entreprise/${val.id_Client}`}
                            >
                              <MdOutlineBusinessCenter className="view-icon" />
                              {index + 1}
                              <hr />
                              {loading ? <Fetching /> : val.societe}
                            </Link>
                          </ul>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
          {/* } */}
        </div>
      </div>
    </>
  );
};
export default Home;
