import { toast } from "react-toastify";
import eolienneService from "../../services/Eolienne";
export const getDataEole = (site_id, groupe_id, box_id) => async (dispatch) => {
  try {
    dispatch({
      type: "AWAITING_REQUPAIRATION",
    });
    const response = await eolienneService.getOneEolienneDataOfOneHourGo(
      site_id,
      groupe_id,
      box_id
    );
    const labels = [];
    const data = [];
    const calculations = [];
    const initcalculations = [];
    if (response.data.CalculEolienne.length && response.data.CalculEolienne) { 
    for (let i = 0; i < response.data.CalculEolienne.length; i++) {
      data.push(response.data.CalculEolienne[i].value);
      labels.push(response.data.CalculEolienne[i].date);
      calculations.push(
        response.data.CalculEolienne[i].value
      );
    }
  }
    var sum = 0;
    calculations.forEach(function (item) {
      return (sum += item);
    });
    initcalculations.push(sum);
    dispatch({
      type: "SUCCESS_REQUPAIRATION",
      payload: {
        data,
        labels,
        initcalculations
      },
    });
  } catch (error) {
    toast.error(error.response.data.description, {
      position: toast.POSITION.TOP_CENTER,
    });
    dispatch({
      type: "REQUPAIRATION_REFUSER",
      payload: {
        error,
      },
    });
  }
};

export const getDataOfWeek =
  (site_id, groupe_id, box_id) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_REQUPAIRATION",
      });
      const response = await eolienneService.getOneEolienneDataOfWeek(
        site_id,
        groupe_id,
        box_id
      );
      const labels = [];
      const data = [];
      const calculations = [];
      const initcalculations = [];
      for (let i = 0; i < response.data.CalculEolinneOfToday.length; i++) {
        data.push(response.data.CalculEolinneOfToday[i].value);
        labels.push(response.data.CalculEolinneOfToday[i].date);
        calculations.push(response.data.CalculEolinneOfToday[i].value);
      }
      var sum = 0;
      calculations.forEach(function (item) {
        return (sum += item);
      });
      initcalculations.push(sum);
      dispatch({
        type: "SUCCESS_REQUPAIRATION",
        payload: {
          data,
          labels,
          initcalculations,
        },
      });
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: "REQUPAIRATION_REFUSER",
        payload: {
          error,
        },
      });
    }
  };
export const getDataOfToday =
  (site_id, groupe_id, box_id) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_REQUPAIRATION",
      });
      const response = await eolienneService.getOneEolienneDataOfToday(
        site_id,
        groupe_id,
        box_id
      );
      const labels = [];
      const data = [];
      const calculations = [];
      const initcalculations = [];
      for (let i = 0; i < response.data.CalculEolinneOfToday.length; i++) {
        data.push(response.data.CalculEolinneOfToday[i].value);
        labels.push(response.data.CalculEolinneOfToday[i].date);
        calculations.push(
          response.data.CalculEolinneOfToday[i].value
        );
      }
      dispatch({
        type: "SUCCESS_REQUPAIRATION",
        payload: {
          data,
          labels,
          initcalculations
        },
      });
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: "REQUPAIRATION_REFUSER",
        payload: {
          error,
        },
      });
    }
  };
export const getDataOfYearOrderedByMonth =
  (site_id, groupe_id, box_id) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_REQUPAIRATION",
      });
      const response =
        await eolienneService.CalculEolinneDataOfYearOrderedByMonth(
          site_id,
          groupe_id,
          box_id
        );
      const labels = [];
      const data = [];
      const calculations = [];
      const initcalculations = [];
      for (
        let i = 0;
        i < response.data.CalculEolinneOfYearorderedByMonth.length;
        i++
      ) {
        data.push(response.data.CalculEolinneOfYearorderedByMonth[i].value);
        labels.push(response.data.CalculEolinneOfYearorderedByMonth[i].date);
        calculations.push(
          response.data.CalculEolinneOfYearorderedByMonth[i].value
        );
      }
      var sum = 0;
    calculations.forEach(function (item) {
      return (sum += item);
    });
    initcalculations.push(sum);
      dispatch({
        type: "SUCCESS_REQUPAIRATION",
        payload: {
          data,
          labels,
          initcalculations
        },
      });
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: "REQUPAIRATION_REFUSER",
        payload: {
          error,
        },
      });
    }
  };

export const getDataOfYearsOrderedByYear =
  (site_id, groupe_id, box_id) => async (dispatch) => {
    try {
      dispatch({
        type: "AWAITING_REQUPAIRATION",
      });
      const response = await eolienneService.CalculEolinneOfYearsOrderedByYear(
        site_id,
        groupe_id,
        box_id
      );
      const labels = [];
      const data = [];
      const calculations = [];
      const initcalculations = [];
      if (response.data.CalculEolinneOfYearorderedByYear.length && response.data.CalculEolinneOfYearorderedByYear) {
      for (
        let i = 0;
        i < response.data.CalculEolinneOfYearorderedByYear.length;
        i++
      ) {
        data.push(response.data.CalculEolinneOfYearorderedByYear[i].value);
        labels.push(response.data.CalculEolinneOfYearorderedByYear[i].date);
        calculations.push(response.data.CalculEolinneOfYearorderedByYear[i].value);
      }
    }
      var sum = 0;
      calculations.forEach(function (item) {
        return (sum += item);
      });
      dispatch({
        type: "SUCCESS_REQUPAIRATION",
        payload: {
          data,
          labels,
          initcalculations,
        },
      });
    } catch (error) {
      toast.error(error.response.data.description, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({
        type: "REQUPAIRATION_REFUSER",
        payload: {
          error,
        },
      });
    }
  };
  export const upDtateState   = (site_id, groupe_id, box_id) => async (dispatch) => {
    const labels = [];
    const data = [];
    const initcalculations = [];
    try {
      dispatch({
        type: "AWAITING_REQUPAIRATION",
      });
      data.push([]);
      labels.push([]);
      initcalculations.push([]);
      dispatch({
        type: "UPDATEING_EOL_STATE",
        payload: {
          data,
          labels,
          initcalculations,
        },
      });
    } catch (error) {
      dispatch({
        type: "REQUPAIRATION_REFUSER",
        payload: {
          error,
          data,
          labels,
          initcalculations,
        },
      });
    }
  };
