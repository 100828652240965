import React from "react";
import "../../assets/styles/pages_css/NotFound.css"
const NotFound = () => {
  return (
    <>
      <div className="bodyNav_Page_not_found">
        <div className="container_buttons_not_found" >
          <h3>ERREUR404</h3>
          <p>La page que vous recherchez n'existe pas</p>
          <div className="buttons_not_found">

          <a  href="https://trackmyroof.com/">
          Retour à la page d'accueil{" "}
          </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotFound;
