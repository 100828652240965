import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/styles/pages_css/NewUser.css";
import Navbar from "../../components/Navbar/Navbar";
import clientService from "../../services/Client";
import userService from "../../services/Users";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendOutlined } from "@material-ui/icons";
toast.configure();
export const Newuser = () => {
  const [enterprise, setEnterprise] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [privileges, setPrivileges] = useState("Visiteur");
  const [id_Client, setId_Client] = useState("");
  const history = useHistory();
  const handleClick = async () => {
    try {
      const res = await userService.signup(
        firstName,
        surname,
        email,
        number,
        password,
        privileges,
        id_Client
      );
      toast.success(res.data.message, { position: toast.POSITION.TOP_CENTER });
      history.push("/newsite");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await clientService.getAll();
        setEnterprise(data.clients);
      } catch (error) {}
    }
    fetchData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="main_user">
        <form className="container select_form">
          <label>
            <div className="haed_new">
              <h4>
                Choisissez une entreprise pour attacher à ce nouvel utilisateur.
              </h4>
              <select
                name="id_Client"
                value={id_Client}
                onChange={(e) => setId_Client(e.target.value)}
              >
                <option value="grapefruit">Choisissez une Entreprise</option>
                {enterprise.map((t, index) => (
                  <option key={index} value={t.id_Client}>
                    {t.societe}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </form>
        <div className="container buttons_user">
          <h2 className="tite_new_user">Nouvel utilisateur</h2>
          <div className="inputs_user">
            <div className="groups">
              <label type="text" className="label">
                Prénom
              </label>
              <input
                name="surname"
                type="firstName"
                className="input_new_utilisater"
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                minLength="4"
              />
            </div>
            <div className="groups">
              <label type="text" className="label">
                Nom
              </label>
              <input
                name="surname"
                type="surname"
                className="input_new_utilisater"
                placeholder="Nom"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
                minLength="4"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs_user">
            <div className="groups">
              <label type="text" className="label">
                E-mail
              </label>
              <input
                name="email"
                type="email"
                className="input_new_utilisater"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="groups">
              <label type="text" className="label">
                Téléphone
              </label>
              <input
                name="Telephone"
                type="Telephone"
                className="input_new_utilisater"
                placeholder="Téléphone"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs_user">
            <form className="groupS">
              <label type="text" className="label">
                Mot de passe
              </label>
              <input
                name="password"
                type="password"
                autoComplete="on"
                className="input_new_utilisater"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </form>
            <div className="groups">
              <label type="text" className="label">
                Privilèges
              </label>
              <select
                className="privileges_new"
                name="privileges"
                value={!privileges || "Visiteur"}
                onChange={(e) => setPrivileges(e.target.value)}
              >
                <option value="Visiteur">Rôle d'utilisateur</option>
                <option value="Admin">Administrateur</option>
                <option value="Visiteur">Utilisateur</option>
              </select>
            </div>
          </div>
          <div className="submit">
            {/* <input
              onClick={handleClick}
              type="submit"
              className="  btn btn-primary me-2 subt"
              value="Envoyer"
            /> */}
            <button className="square subt" type="submit" onClick={handleClick}>
              Envoyer | <SendOutlined />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newuser;
