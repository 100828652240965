import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/styles/pages_css/NewEnterprise.css";
import Navbar from "../../components/Navbar/Navbar";
import clientService from "../../services/Client";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendOutlined } from "@material-ui/icons";
toast.configure();
const Newclient = () => {
  const [societe, setSociete] = useState("");
  const [secteur, setSecteur] = useState("");
  const [adresseClient, setAdresseClient] = useState("");
  const [telephoneClient, setTelephoneClient] = useState("");
  const [pays, setPays] = useState("");
  const history = useHistory();
  const handleClick = async () => {
    try {
      await clientService.registre(
        societe,
        secteur,
        adresseClient,
        telephoneClient,
        pays
      );
      toast.success("vous avez ajouté une Enterprise", {
        position: toast.POSITION.TOP_CENTER,
      });
      history.push("/enterprises");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      <Navbar />
      <div className="main_enterprise">
        <div className="App_new_enterprise">
          <div className=" new_entrepris_buttons">
            <h2 className="titele_new_entrepris">
              Nouvelle entreprise / client
            </h2>
            <div className="inputs_new_enterprise">
              <div className="groups_new_enterprise">
                <label htmlFor="societe" className="label">
                  Nom
                  <span className="prompt-icon">?</span>
                </label>
                <input
                  name="societe"
                  type="societe"
                  id="societe"
                  className="input_new_enterprise"
                  value={societe}
                  onChange={(e) => setSociete(e.target.value)}
                  required
                  placeholder="Nom de la société"
                />
                <div className="prompt-icon-div">
                  <span className="prompt-icons">?</span>
                  <span className="prompt-icon">hdhshhshshhshss</span>
                </div>
              </div>
              <div className="groups_new_enterprise">
                <label htmlFor="secteur" className="label">
                  secteur
                </label>
                <input
                  name="secteur"
                  type="secteur"
                  className="input_new_enterprise"
                  value={secteur}
                  onChange={(e) => setSecteur(e.target.value)}
                  required
                  placeholder="Nom du secteur"
                />
                <div className="prompt-icon-div">
                  <span className="prompt-icons">?</span>
                  <span className="prompt-icon">hdhshhshshhshss</span>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="inputs_new_enterprise">
              <div className="groups_new_enterprise">
                <label htmlFor="adresseClient" className="label ">
                  Adresse
                </label>

                <input
                  name="adresseClient"
                  type="text"
                  className="input_new_enterprise"
                  value={adresseClient}
                  onChange={(e) => setAdresseClient(e.target.value)}
                  required
                  placeholder="Adresse du client"
                />
                <div className="prompt-icon-div">
                  <span className="prompt-icons">?</span>
                  <span className="prompt-icon">hdhshhshshhshss</span>
                </div>
              </div>
              <div className="groups_new_enterprise">
                <label htmlFor="pays" className="label">
                  {" "}
                  Pays
                </label>
                <input
                  name="pays"
                  type="text"
                  className="input_new_enterprise"
                  value={pays}
                  onChange={(e) => setPays(e.target.value)}
                  required
                  placeholder="Pays du client"
                />
                <div className="prompt-icon-div">
                  <span className="prompt-icons">?</span>
                  <span className="prompt-icon">mettez le </span>
                </div>
              </div>
            </div>
            <div className="inputs_user">
              <div className="groups_new_enterprise">
                <label
                  htmlFor="telephoneClient"
                  className="label label-with-prompt"
                >
                  Téléphone
                </label>
                <input
                  name="telephoneClient"
                  type="number"
                  value={telephoneClient}
                  className="input_new_enterprise label-with-prompt"
                  onChange={(e) => setTelephoneClient(e.target.value)}
                  required
                  placeholder="Téléphone du client"
                />
                <div className="prompt-icon-div">
                  <span className="prompt-icons">?</span>
                  <span className="prompt-icon">C'est le numéro avec lequel vous pouvez contacter le client.</span>
                </div>
              </div>
            </div>
            <div className="submit">
              <button
                className="square subt"
                type="submit"
                onClick={handleClick}
              >
                Envoyer | <SendOutlined />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newclient;
