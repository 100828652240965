const initalState = {
  loading: false,
  data: [],
};
const clientReducer = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "AWAITING_BITCOIN":
      return {
        ...state,
        loading: true,
      };
    case "REJECTED_BITCOIN":
      return {
        ...state,
        loading: false,
      };
    case "SUCCESS_BITCOIN":
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    default:
      return state;
  }
};
export default clientReducer;
