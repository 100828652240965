import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/componentes_css/Footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-6 align-item-center">
            <div className="d-flex justify-content-center"></div>
            <p className="pt-3 text-align-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;WIND my ROOF | All Rigths Reservd
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
