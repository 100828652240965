import api from "./api.js";
const clientService = {
  registre: async (societe, secteur, adresseClient, telephoneClient, pays) => {
    const cleint = { societe, secteur, adresseClient, telephoneClient, pays };
    return await api.post("clients/registre", cleint);
  },
  getAllClients: async () => {
    return await api.get("/clients");
  },
  getAll: async () => {
    return await api.get("/clients");
  },
  getById: async (id_Client) => {
    return await api.get(`/clients/client/${id_Client}`)
  },
  editEnterpriseById: async (id_Client, enterprise) => {
    return await api.put(`/clients/client/edit/${id_Client}`, enterprise);
  },
  deleteEnterpriseById: async (id_Client) => {
    return await api.delete(`/clients/client/delete/${id_Client}`);
  },
};
export default clientService;