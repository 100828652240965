import api from "./api.js";
const paneauService = {
  registre: async ( groupe_id, site_id) => {
    const paneau = {groupe_id, site_id };
    return await api.post("panneau/add_panneau", paneau);
  },
  getAll: async () => {
    return await api.get("panneau");
  },
};
export default paneauService;