import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_DEV}`,
  withCredentials: true,
});
api.interceptors.request.use((request) => {
  if (request.headers)
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;
  return request;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
   
    if (error.response.status !== 401) {
      // const confirm = window.confirm('Token expired. Do you want to refresh it?');
      // if (confirm) {
        return Promise.reject(error);
      } 
        
        try {
          const response = await api.get("users/auth/refresh");
          const token = response.data.token;
          localStorage.setItem("token", token);
          error.hasRefreshedToken = true;
          return Promise.reject(error);
        } catch (error) {
          const tokenError = new Error("Cannot refresh token");
          return Promise.reject(tokenError);
        }
      
    
  }
);
export default api;
