import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/styles/pages_css/Newsite.css";
import Navbar from "../../components/Navbar/Navbar";
// import userService from '../../services/Users';
import siteService from "../../services/Site";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clientService from "../../services/Client";

import { SendOutlined } from "@material-ui/icons";
toast.configure();
const Newsite = () => {
  const [user, setUser] = useState([]);
  const [enterprise, setEnterprise] = useState([]);
  const [nomSite, setNomSite] = useState("");
  const [longitudeSite, setLongitudeSite] = useState("");
  const [latitudeSite, setLatitudeSite] = useState("");
  const [adresseSite, setAdresseSite] = useState("");
  const [dateInstall, setDateInstall] = useState("");
  const [buildingSizeh, setBuildingSizeh] = useState("");
  const [buildingSizev, setBuildingSizev] = useState("");
  const [id_User, setId_User] = useState("");
  const [id_Client, setId_Client] = useState("");
  const [Nombre, setNombre] = useState("");
  const history = useHistory();
  const handleClick = async () => {
    try {
      await siteService.register(
        nomSite,
        longitudeSite,
        latitudeSite,
        adresseSite,
        dateInstall,
        buildingSizeh,
        buildingSizev,
        id_User,
        id_Client
      );
      toast.success("vous avez ajouté un site", {
        position: toast.POSITION.TOP_CENTER,
      });
      history.push("/sites");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const profilEnterprise = async () => {
    try {
      const result = await clientService.getById(id_Client);
      setUser(result.data.users);
    } catch (error) {}
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await clientService.getAll();
        setEnterprise(data.clients);
      } catch (error) {}
    }
    fetchData();
    fetchData();
    profilEnterprise();
  }, [id_Client]);
  return (
    <>
      <Navbar />
      <div className="main_site">
        <div></div>
        <div className="deux_option_site">
          <form className="container select_form">
            <h4>Choisissez une Entreprise pour attacher au nouveau site.</h4>
            <label>
              <div>
                <select
                  name="id_Client"
                  value={id_Client}
                  // onChange={profilEnterprise}
                  onChange={(e) => {
                    setId_Client(e.target.value);
                    profilEnterprise();
                  }}
                >
                  <option value="">Liste d'Entreprises</option>
                  {enterprise.map((t, index) => (
                    <option key={index} value={t.id_Client}>
                      {t.societe}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </form>
          {/*  */}
          <form className="container select_form">
            <h4>Choisissez un utilisateur pour attacher au nouveau site.</h4>
            <label>
              <div>
                <select
                  name="id_User"
                  value={id_User}
                  onChange={(e) => setId_User(e.target.value)}
                >
                  <option value="grapefruit">List d'utilisateurs</option>
                  {user.map((t, index) => (
                    <option key={index} value={t.id_User}>
                      {t.surname}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </form>
        </div>
        {/*  */}
        <div className="container buttons_new_site">
          <h2 className="titele_new_siet">Nouveau site</h2>
          <div className="inputs_new_site">
            <div className="groups">
              <label type="text" className="label">
                Nom
              </label>
              <input
                name="nomSite"
                type="Nom"
                className="input_site"
                placeholder="nom de site"
                value={nomSite}
                onChange={(e) => setNomSite(e.target.value)}
                required
                minLength="4"
              />
            </div>
            <div className="groups_new_site">
              <label type="number" className="label">
                Longitude
              </label>
              <input
                name="longitudeSite"
                type="longitudeSite"
                className="input_site"
                placeholder="longitude de site"
                value={longitudeSite}
                onChange={(e) => setLongitudeSite(e.target.value)}
                required
                minLength="4"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs_new_site">
            <div className="groups_new_site">
              <label type="number" className="label">
                Latitude
              </label>
              <input
                name="latitudeSite"
                type="latitudeSite"
                className="input_site"
                placeholder="latitude de site"
                value={latitudeSite}
                onChange={(e) => setLatitudeSite(e.target.value)}
                required
                minLength="4"
              />
            </div>
            <div className="groups_new_site">
              <label type="text" className="label">
                Adresse
              </label>
              <input
                name="adresseSite"
                type="adresseSite"
                className="input_site"
                placeholder=" adresse de site"
                value={adresseSite}
                onChange={(e) => setAdresseSite(e.target.value)}
                required
                minLength="4"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs_new_site">
            <div className="groups_new_site">
              <label type="text" className="label">
                buildingSizeh
              </label>
              <input
                name="buildingSizeh"
                type="buildingSizeh"
                className="input_site"
                placeholder="taille du bâtiment horizontale"
                value={buildingSizeh}
                onChange={(e) => setBuildingSizeh(e.target.value)}
                required
                minLength="4"
              />
            </div>
            <div className="groups_new_site">
              <label type="text" className="label">
                buildingSizev
              </label>
              <input
                name="buildingSizev"
                type="buildingSizev"
                className="input_site"
                placeholder="taille du bâtiment vertical"
                value={buildingSizev}
                onChange={(e) => setBuildingSizev(e.target.value)}
                required
                minLength="4"
              />
            </div>
          </div>
          <div className="inputs_new_site">
            <div className="groups_new_site">
              <label type="text" className="label">
              Date
              </label>
              <input
                name="buildingSizev"
                type="buildingSizev"
                className="input_site"
                placeholder="date de installation"
                value={dateInstall}
                onChange={(e) => setDateInstall(e.target.value)}
                required
                minLength="4"
              />
            </div>
            {/*  */}
            <div className="groups_new_site">
              <label type="number" className="label">
                WindBox
              </label>
              <input
                name="Nombre"
                type="Nombre"
                className="input_site"
                placeholder="nombre de WindBox"
                value={Nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
                minLength="4"
              />
            </div>
          </div>
          <div className="submit inputs_submit">
            {/* < Bottons
         type="submit"
         onClick={handleClick}
         className="  btn btn-primary me-2 subt"
         value="Envoyer"
         /> */}
            {/* <input
              type="submit"
              onClick={handleClick}
              className="  btn btn-primary me-2 subt"
              value="Envoyer"
            /> */}
            <button className="square" type="submit" onClick={handleClick}>
              Envoyer | <SendOutlined />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newsite;
