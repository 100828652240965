const initalState = {
  loading: false,
  error: null,
  initcalculations: [],
  data: {
    labels: [],
    datasets: [
      {
        label: "Kwt",
        data: [],
        backgroundColor: "rgba(68,175,0, 0.4)",
        borderColor: "rgba(238,175,0, 0.5)",
        pointBorderColor: "rgba(238,175,0, 0.7)",
      },
    ],
  },
};

const eolienneReducer = (state = initalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "AWAITING_REQUPAIRATION":
      return {
        ...state,
        loading: true,
      };
    case "REQUPAIRATION_REFUSER":
      return {
        ...state,
        error: payload.error,
        loading: false,
        initcalculations: payload.initcalculations,
        data: {
          labels: payload.labels,
          datasets: [
            {
              label: "kwt",
              data: payload.data,
              backgroundColor: "rgba(68,175,0, 0.4)",
              borderColor: "rgba(238,175,0, 0.5)",
              pointBorderColor: "rgba(238,175,0, 0.7)",
            },
          ],
        },
      };
    case "SUCCESS_REQUPAIRATION":
      return {
        ...state,
        loading: false,
        initcalculations: payload.initcalculations,
        data: {
          labels: payload.labels,
          datasets: [
            {
              label: "kwt",
              data: payload.data,
              backgroundColor: "rgba(68,175,0, 0.4)",
              borderColor: "rgba(238,175,0, 0.5)",
              pointBorderColor: "rgba(238,175,0, 0.7)",
            },
          ],
        },
      };
      case "UPDATEING_EOL_STATE":
        return {
          ...state,
          loading: false,
          initcalculations: payload.initcalculations,
          data: {
            labels: payload.labels,
            datasets: [
              {
                label: "kwt",
                data: payload.data,
                backgroundColor: "rgba(68,175,0, 0.4)",
                borderColor: "rgba(238,175,0, 0.5)",
                pointBorderColor: "rgba(238,175,0, 0.7)",
              },
            ],
          },
        };
    default:
      return state;
  }
};
export default eolienneReducer;
