import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/componentes_css/Navbar.css";
import logo1 from "../../assets/sources/images/logo1.webp";
import setting from "../../assets/sources/images/settings.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { IoPersonAddSharp, IoSettingsOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { BsBuilding } from "react-icons/bs";
import { FaSolarPanel } from "react-icons/fa";
import { GiWindTurbine } from "react-icons/gi";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { useDispatch } from "react-redux";
import { upDateState, } from "../../store/actions/site.action";


const Navbar = () => {
const dispatch = useDispatch();
const { id_Site } = useParams();
  const fe = (id_Site) => {
    dispatch(
      upDateState({
        id: id_Site,
      })
    );
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top ">
        <div className="container">
          <Link to="/accueil" className="navbar-brand">
            <img className="logo_un" src={logo1} alt="logoWindmyroof" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars} style={{ color: "none" }} />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item active">
                <Link to="/home" className="nav-link"
                 onClick={() => fe(id_Site)}
                >
                  accueil
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/enterprises"
                  className="nav-link nav_link_Entreprises"
                  onClick={() => fe(id_Site)}
                >
                  Entreprises
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/sites" className="nav-link nav_link_Sites"
                onClick={() => fe(id_Site)}
                >
                  Sites
                </Link>
              </li>
              <div className="dropdown ">
                <li className=" nav-item ">
                  <Link to="#" className="nav-link f1">
                    Création /nouveau
                  </Link>
                </li>
                <div className="dropdown-content">
                  <div className="headerCel2User__infos">
                    <Link to="/newclient" className="navlink info_perso1">
                      <MdOutlineBusinessCenter className="view-icon" />
                      <p>Nouvelle entreprise</p>
                    </Link>
                  </div>
                  <div>
                    <Link to="/newuser" className="navlink info_perso1">
                      <IoPersonAddSharp className="view-icon" />
                      <p>Nouvel utilisateur</p>
                    </Link>
                  </div>
                  <div className="headerCel2User__infos">
                    <Link to="/newsite" className="navlink info_perso1">
                      <BsBuilding className="view-icon" />
                      <p>Nouveau site</p>
                    </Link>
                  </div>
                  <div className="headerCel2User__infos">
                    <Link to="/neweolienne" className="navlink info_perso1">
                      <GiWindTurbine className="view-icon" />
                      <p>Nouvelle éolienne</p>
                    </Link>
                  </div>
                  <div className="headerCel2User__infos">
                    <Link to="/newpaneau" className="navlink info_perso1">
                      <FaSolarPanel className="view-icon" />
                      <p>Nouveau paneau</p>
                    </Link>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="dropdown">
                <li className=" nav-item ">
                  <Link to="#" className="nav-link f1">
                    <img
                      className="logo_setting"
                      src={setting}
                      alt="settinglogo"
                    />
                  </Link>
                </li>
                <div className="dropdown-content_one space">
                  <div className="headerCel2User__infos">
                    <Link className="info_perso1" to="/profie">
                      <IoSettingsOutline className="view-icon" />
                      Confidentialité
                    </Link>
                  </div>
                  <div className="c-headerCel2User__infos">
                    <Link className="navlink info_perso1" to="/profie">
                      <CgProfile className="view-icon" />
                      <p>Profil</p>
                    </Link>
                  </div>
                  <div className="c-headerCel2User__infos">
                    <Link
                      onClick={() => {
                        localStorage.clear();
                        dispatch({ type: "CLEAR" });
                      }}
                      className="navlink info_perso1"
                      to="/"
                    >
                      <FiLogOut className="view-icon" />
                      <p>Déconnexion</p>
                    </Link>
                  </div>
                </div>
              </div>
              {/*  */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
