import api from "./api.js";
const eolienneService = {
  registre: async (box_id, groupe_id, site_id) => {
    const cleint = { box_id, groupe_id, site_id };
    return await api.post("eoliennes/add_eolienne", cleint);
  },
  getAll: async () => {
    return await api.get("clients");
  },
  getOneEolienneDataOfOneHourGo: async ({
    id: site_id,
    idOne: groupe_id,
    idtwo: box_id,
  }) => {
    return await api.get(
      `eoliennes/eolienne/of_one_hour_go/${site_id}/${groupe_id}/${box_id}`
    );
  },
  getOneEolienneDataOfToday: async ({
    id: site_id,
    idOne: groupe_id,
    idtwo: box_id,
  }) => {
    return await api.get(
      `eoliennes/eolienne/of_today_by_hour/${site_id}/${groupe_id}/${box_id}`
    );
  },
  getOneEolienneDataOfWeek: async ({
    id: site_id,
    idOne: groupe_id,
    idtwo: box_id,
  }) => {
    return await api.get(
      `eoliennes/eolienne/of_week/${site_id}/${groupe_id}/${box_id}`
    );
  },
  CalculEolinneDataOfYearOrderedByMonth: async ({
    id: site_id,
    idOne: groupe_id,
    idtwo: box_id,
  }) => {
    return await api.get(
      `eoliennes/eolienne/of_year_by_month/${site_id}/${groupe_id}/${box_id}`
    );
  },
  CalculEolinneOfYearsOrderedByYear: async ({
    id: site_id,
    idOne: groupe_id,
    idtwo: box_id,
  }) => {
    return await api.get(
      `eoliennes/eolienne/of_year_by_years/${site_id}/${groupe_id}/${box_id}`
    );
  },
};

export default eolienneService;
