import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../assets/styles/pages_css/NewEnterprise.css";
import Navbar from "../../components/Navbar/Navbar";
import clientService from "../../services/Client";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
toast.configure();
const Detaille_entreprise = (props) => {
  const useStyles = makeStyles({
    row: {
      backgroundColor: "#16171a",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#131111",
      },
      fontFamily: "Montserrat",
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "black",
      },
    },
  });
  const classes = useStyles();
  const [site, setSite] = useState("");
  const [enterprise, setEnterprise] = useState("");
  const { id_Client } = useParams();
  const [page, setPage] = useState(1);
  let n = 1;
  const searchtem = "";
  useEffect(() => {
    async function detaileEnterprise() {
      try {
        const result = await clientService.getById(id_Client);
        setEnterprise(result.data);
        setSite(result.data.sites);
      } catch (error) {}
    }
    detaileEnterprise();
  }, [id_Client]);
  for (let i = 0; i < site.length; i++) {
    if (i <= 10) {
      n = 1;
    } else {
      n = 10;
    }
  }
  return (
    <>
      <Navbar />
      <div className="app_main_detaile_enterprises">
        <section className="de_1 py-4">
          <Link className="btn btn-primary de_2" to="/enterprises">
            Voir toutes les entreprises
          </Link>
          <h4 className="titele_site_id">
            {enterprise.societe}Sites de l'entreprise choisie:
            {enterprise.societe}
          </h4>
          <p>Liste de sites rattachés à entreprise {enterprise.societe}.</p>
        </section>
        <table className="table">
          <thead>
            <tr>
              <th>S.No</th>
              <th scope="col">NomSite</th>
              <th scope="col">AdresseSite</th>
              <th scope="col">DateInstall</th>
            </tr>
          </thead>
          <tbody>
            {/*  */}
            {site &&
              site
                .filter((val) => {
                  return val.nomSite
                    .toLowerCase()
                    .includes(searchtem.toLowerCase());
                })
                .slice((page - 1) * 10, (page - 1) * 10 + 10)
                .map((val, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="E.No">{index + 1}</td>
                      <td data-label="societe" className="td_hr">
                        <Link to={`/site/${val.id_Site}`}>{val.nomSite}</Link>
                      </td>
                      <td data-label="email">{val.adresseSite}</td>
                      <td data-label="privileges">{val.dateInstall}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <Pagination
          count={n}
          style={{
            padding: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          classes={{ul: classes.pagination }}
          onChange={(_, value) => {
            setPage(value);
            window.scroll(0, 5);
          }}
        />
      </div>
    </>
  );
};
export default Detaille_entreprise;
