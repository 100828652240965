import api from "./api.js";
const userService = {
  signup: async (firstname, surname, email, number, password, privileges, client_id) => {
    const user = { email, password, firstname, surname, number, privileges, client_id };
    return await api.post("users/register", user);
  },
  login: async (email, password ,privileges) => {
    const user = { email, password, privileges };
    return await api.post("users/login", user);
  },
  getAll: async () => {
    return await api.get("users");
  },
  editUserById: async (id_User,User) => {
    return await api.put(`users/user_edit/${id_User}`,User);
  },
 getById: async (id_User) => {
    return await api.get('/users/user/' + id_User)
  },
  deleteUserById: async (id_User) => {
    return await api.delete(`/users/user/delete/${id_User}`)
  },
  updatePassword: async (password) => {
    return await api.put('user', { password })
  }
};
export default userService;