import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/styles/pages_css/Newsite.css";
import Navbar from "../../components/Navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import paneauService from "../../services/Paneau";
import siteService from "../../services/Site";
import { SendOutlined } from "@material-ui/icons";
toast.configure();
const Newpaneau = () => {
  const [site, setSite] = useState([]);
  const [groupe_id, setGroupe_Id] = useState("");
  const [id_Site, setId_Site] = useState("");
  const history = useHistory();
  const handleClick = async () => {
    try {
      await paneauService.registre(groupe_id, id_Site);
      toast.success("vous avez ajouté un nouveau paneau", {
        position: toast.POSITION.TOP_CENTER,
      });
      history.push("/sites");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await siteService.getAll();
        setSite(data.sites);
      } catch (error) {}
    }
    fetchData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="main_site">
        <div className="deux_option_site">
          <form className="container select_form">
            <label>
              <div>
                <h4>Choisissez un site pour attacher à ce nouveau paneau.</h4>
                <select
                  name="id_User"
                  value={id_Site}
                  onChange={(e) => setId_Site(e.target.value)}
                >
                  <option value="grapefruit">Choisissez un Site</option>
                  {site.map((t, index) => (
                    <option key={index} value={t.id_Site}>
                      {t.nomSite}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </form>
        </div>
        {/*  */}
        <div className="container buttons_new_site">
          <h2 className="titele-new_eolenne">nouveau paneau</h2>
          <div className="inputs_new_site">
            <div className="groups">
              <label type="number" className="label">
                groupe Id
              </label>
              <input
                name="groupe_Id"
                type="groupe_Id"
                className="input_site"
                placeholder=" mettez le groupe_Id"
                value={groupe_id}
                onChange={(e) => setGroupe_Id(e.target.value)}
                required
                minLength="4"
              />
            </div>
          </div>
          <div className="submit inputs_submit">
            <button className="square subt" 
               type="submit"
               onClick={handleClick}
             >Envoyer | <SendOutlined/></button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newpaneau;
