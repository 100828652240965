import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "../../assets/styles/pages_css/EditeEnterprise.css";
import clientService from "../../services/Client";
import { SendOutlined } from "@material-ui/icons";
const EditEnterprise = () => {
  let history = useHistory();
  const { id_Client } = useParams();
  const [enterprise, setEnterprise] = useState({
    adresseClient: "",
    pays: "",
    secteur: "",
    societe: "",
    telephoneClient: "",
  });
  const { adresseClient, pays, secteur, societe, telephoneClient } = enterprise;
  const onInputChange = (e) => {
    setEnterprise({ ...enterprise, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    loadEnterprise();
  }, []);
  const onSubmit = async (e) => {
    try {
      let confumMessage =
        "Vous êtes sûr de vouloir Modifier les donnes de cette entreprise.";
      if (window.confirm(confumMessage)) {
        e.preventDefault();
        await clientService.editEnterpriseById(id_Client, enterprise);
        history.push("/enterprises");
      } else {
        history.push("/enterprises");
      }
    } catch (error) {}
  };
  const loadEnterprise = async () => {
    try {
      const result = await clientService.getById(id_Client);
      setEnterprise(result.data);
    } catch (error) {}
  };
  return (
    <>
      <Navbar />
      <div className="main_enterprise_edit">
        <form onSubmit={(e) => onSubmit(e)} className="container buttons">
          <h2> Edite d'entreprise</h2>
          <div className="inputs">
            <div className="groups">
              <label htmlFor="societe" className="label">
                Nom/societe
              </label>
              <input
                name="societe"
                type="societe"
                className="input"
                value={societe}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="Nom da societe"
              />
            </div>
            <div className="groupS">
              <label htmlFor="secteur" className="label">
                Nom/secteur
              </label>
              <input
                name="secteur"
                type="secteur"
                className="input"
                value={secteur}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="secteur"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs">
            <div className="groups">
              <label htmlFor="adresseClient" className="label">
                Adresse
              </label>
              <input
                name="adresseClient"
                type="adresseClient"
                className="input"
                value={adresseClient}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="adresse de Client"
              />
            </div>
            <div className="groupS">
              <label type="text" className="label">
                {" "}
                Pays
              </label>
              <input
                name="pays"
                type="pays"
                className="input"
                value={pays}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="pays de client"
              />
            </div>
          </div>
          <div className="inputs ">
            <div className="groups">
              <label type="number" className="label">
                Téléphone
              </label>
              <input
                name="telephoneClient"
                type="telephoneClient"
                value={telephoneClient}
                className="input"
                onChange={(e) => onInputChange(e)}
                required
                placeholder="telephone de Client"
              />
            </div>
          </div>
          <div className="submit subt">
            <button onSubmit={(e) => onSubmit(e)} className="square">
              Enregister les modifcation | <SendOutlined />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditEnterprise;
