import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import "../../assets/styles/pages_css/EditSite.css";
import siteService from "../../services/Site";
import { toast } from "react-toastify";
import clientService from "../../services/Client";
import { SendOutlined } from "@material-ui/icons";
const EditSite = () => {
  let history = useHistory();
  const { id_Site } = useParams();
  const [user, setUser] = useState({
    nomSite: "",
    longitudeSite: "",
    latitudeSite: "",
    adresseSite: "",
    buildingSizeh: "",
    buildingSizev: "",
    dateInstall: "",
    client_id: "",
    user_id: "",
  });
  const {
    nomSite,
    longitudeSite,
    latitudeSite,
    adresseSite,
    buildingSizeh,
    buildingSizev,
    dateInstall,
    // client_id,
    user_id,
  } = user;
  const [enterprise, setEnterprise] = useState("");
  // const [id_User, setId_User] = useState("");
  const [id_Client, setId_Client] = useState("");
  const [userData, setUserData] = useState([]);
  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    try {
      let confumMessage =
        "Vous êtes sûr de vouloir Modifier les donnes de ce site.";
      if (window.confirm(confumMessage)) {
        e.preventDefault();
        const { data } = await siteService.editSiteById(id_Site, user);
        toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
        history.push("/sites");
      } else {
        history.push("/sites");
      }
    } catch (error) {}
  };
  const loadSite = async () => {
    try {
      const result = await siteService.getByIdOne(id_Site);
      setUser(result.data);
    } catch (error) {}
  };
  const fetchData = async () => {
    try {
      const { data } = await clientService.getAll();
      setEnterprise(data.clients);
    } catch (error) {}
  };
  const profilEnterprise = async () => {
    try {
      const result = await clientService.getById(id_Client);
      setUserData(result.data.users);
    } catch (error) {}
  };
  useEffect(() => {
    loadSite();
    fetchData();
    profilEnterprise();

  }, [id_Client]);
  return (
    <>
      <Navbar />
      <div className="App_new_site">
        <form onSubmit={(e) => onSubmit(e)} className="container buttons ">
          <h2 className="titele_deit_entrepris"> Edit d'un Site</h2>
          {/*  */}
          <div className="inputs select_item">
            <label className="select_p">
              <span>Choisissez une Enterprise pour attacher à ce site.</span>
              <select
                name="id_Client"
                value={id_Client}
                onChange={(e) => {
                  setId_Client(e.target.value);
                  profilEnterprise();
                }}
              >
                <option value="">List Enterprise</option>
                {enterprise &&
                  enterprise.map((t, index) => (
                    <option
                      key={index}
                      name="client_id"
                      value={t.id_Client}
                      onChange={(e) => onInputChange(e)}
                    >
                      {t.societe}
                    </option>
                  ))}
              </select>
            </label>
            {/*  */}
            <label className="select_p">
              <span>Choisissez un utilisateur pour attacher à ce site.</span>
              <select
                name="user_id"
                value={user_id}
                onChange={(e) => onInputChange(e)}
              >
                <option value="grapefruit">List d'utilisateurs</option>
                {userData &&
                  userData.map((t, index) => (
                    <option
                      key={index}
                      // onChange={(e) => setId_User(e.target.value)}
                      value={t.id_User}
                    >
                      {t.surname}
                    </option>
                  ))}
              </select>
            </label>
          </div>
          {/*  */}
          <div className="inputs">
            <div className="groups">
              <label htmlFor="nomSite" className="label">
                Nom
              </label>
              <input
                name="nomSite"
                type="nomSite"
                className="input"
                value={nomSite}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="nom de site"
              />
            </div>
            <div className="groupS">
              <label type="text" className="label">
                Longitude
              </label>
              <input
                name="longitudeSite"
                type="longitudeSite"
                className="input"
                value={longitudeSite}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="longitude de site"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs">
            <div className="groups">
              <label htmlFor="adresseSite" className="label">
                adresseSite
              </label>
              <input
                name="adresseSite"
                type="adresseSite"
                className="input"
                value={adresseSite}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="adresse de site"
              />
            </div>
            <div className="groupS">
              <label htmlFor="latitudeSite" className="label">
                latitudeSite
              </label>
              <input
                name="latitudeSite"
                type="latitudeSite"
                className="input"
                value={latitudeSite}
                onChange={(e) => onInputChange(e)}
                required
                placeholder="latitude de site"
              />
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="inputs">
            <div className="groups">
              <label type="number" className="label">
                Sizeh
              </label>
              <input
                name="buildingSizeh"
                type="buildingSizeh"
                value={buildingSizeh}
                className="input"
                onChange={(e) => onInputChange(e)}
                required
                placeholder="building Sizeh de site"
              />
            </div>
            <div className="groups">
              <label type="number" className="label">
                Sizev
              </label>
              <input
                name="buildingSizev"
                type="buildingSizev"
                value={buildingSizev}
                className="input"
                onChange={(e) => onInputChange(e)}
                required
                placeholder="telephone de Client"
              />
            </div>
          </div>
          {/*  */}
          <div className="inputs">
            <div className="groups">
              <label type="number" className="label">
                dateInstall
              </label>
              <input
                name="dateInstall"
                type="dateInstall"
                value={dateInstall}
                className="input"
                onChange={(e) => onInputChange(e)}
                required
                placeholder="telephone de Client"
              />
            </div>
          </div>
          <div className="submit_subt">
            <button onSubmit={(e) => onSubmit(e)} className="square">
              Enregister les modifcation | <SendOutlined />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditSite;
