import React, { useState } from "react";
import "../../assets/styles/pages_css/Accueil.css";
import { HomeNav } from "../../components/Partials/HomeNav";
import { useHistory } from "react-router-dom";
import userService from "../../services/Users";
import { toast } from "react-toastify";
import CookiePopUp from "../../components/CookiePopUp/CookiePopUp";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";

const Accueil = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const history = useHistory();
  const handleClick = async (props) => {
    try {
      const { data } = await userService.login(email, password);
      localStorage.setItem("token", data.token);
      // dispatch({ type: "USER", payload: data.user });
      toast.success(data.message, { position: toast.POSITION.TOP_CENTER });
      localStorage.setItem("user", JSON.stringify(data.user.id_User));
      localStorage.setItem("Role", JSON.stringify(data.user.privileges));
      history.push("/home");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };
  return (
    <>
      <div className="body">
        <HomeNav />
        <div className="bodyNav">
          <HomeNav />
          {/* <CookiePopUp /> */}
          <div className="login-wrap">
            <div className="login-html">
              <label className="tab">SIGN IN</label>
              <div className="login-form">
                <form>
                  <div className="group">
                    <label htmlFor="user" className="label">
                      E-mail
                    </label>
                    <input
                      name="email"
                      type="text"
                      className="input_email input"
                      id="input_email"
                      placeholder="E-mail"
                      value={email}
                      autoComplete="on"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="password" className="label">
                      Mot de passe
                    </label>
                    <div className="input-field input_password">
                      <input
                        name="password"
                        id="input_password"
                        placeholder="Mot de passe"
                        value={password}
                        autoComplete="on"
                        onChange={(e) => setPassword(e.target.value)}
                        type={type}
                      />
                      <span onClick={handleToggle}>
                        <Icon icon={icon} size={25} />
                      </span>
                    </div>
                  </div>
                </form>
                <div className="group-bt">
                  <input
                    type="submit"
                    onClick={handleClick}
                    className="button"
                    value="SE CONNECTER"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Accueil;
