import React, { Fragment } from "react";
import "../assets/styles/componentes_css/fetching.css";
import { FaSpinner } from "react-icons/fa";
const Fetching = () => (
  <>
  <div className="fetching">
    <FaSpinner className="spinner" />Loading please wait ...
  </div>
  </>
);
export default Fetching;
