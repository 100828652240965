import { combineReducers } from "redux";
import siteReducer from "./siteReducer";
import eolienneReducer from "./eolienneReducer";
import clienReducer from "./clientReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  bitcoin: siteReducer,
  eolienn: eolienneReducer,
  client: clienReducer,
  user: userReducer,

});
export default rootReducer;
